import "./UserAgreement.scss";
import React from "react";

const UserAgreement = () => {
    return (
        <div className="UserAgreement">
            <div className="UserAgreement__content">
                <h1>User Agreement</h1>
                <p>
                    This User Agreement governs your use of Botenders Inc.'s services. By accessing or using our services, you agree to comply with this agreement.
                </p>
                <h2>Acceptance of Terms</h2>
                <p>
                    By accessing our platform, you agree to be bound by these terms. If you do not agree with any part of these terms, you should discontinue using our services.
                </p>
                <h2>Account Responsibilities</h2>
                <p>
                    You are responsible for maintaining the confidentiality of your account and password, and for all activities that occur under your account.
                </p>
                <h2>Termination</h2>
                <p>
                    We reserve the right to terminate or suspend your account at any time if you violate this agreement or engage in any harmful behavior.
                </p>
            </div>
        </div>
    );
}

export default UserAgreement;