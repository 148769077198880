import React, { useState } from 'react';
import {
    Copy,
    Key,
    CheckCircle2,
    Globe,
    // Shield,
    Terminal,
    RefreshCcw,
    AlertCircle,
    ExternalLink
} from 'lucide-react';

const Developer = ({ token }) => {
    const [copied, setCopied] = useState(false);
    const [showFullKey, setShowFullKey] = useState(false);
    const [environment, setEnvironment] = useState('development');
    const [showRegenDialog, setShowRegenDialog] = useState(false);

    const maskApiKey = (key) => {
        return showFullKey ? key : `${key?.substring(0, 8)}...${key?.substring(key?.length - 8)}`;
    };

    const copyToClipboard = () => {
        navigator.clipboard.writeText(token).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000);
        });
    };

    const CodeSnippet = ({ language, code }) => (
        <div className="relative mt-2">
            <div className="absolute right-2 top-2">
                <button
                    onClick={() => navigator.clipboard.writeText(code)}
                    className="p-2 text-gray-500 hover:text-gray-700 rounded-md hover:bg-gray-100"
                >
                    <Copy className="w-4 h-4" />
                </button>
            </div>
            <pre className="bg-gray-900 rounded-lg p-4 overflow-x-auto">
                <code className="text-gray-100 text-sm">{code}</code>
            </pre>
        </div>
    );

    return (
        <div className="max-w-4xl mx-auto space-y-6 p-6">
            {/* API Key Section */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="p-6">
                    <h2 className="text-2xl font-semibold text-gray-900">API Credentials</h2>
                    <p className="mt-2 text-gray-600">
                        Your API key provides access to the Botenders API. Keep it secure and never share it publicly.
                    </p>

                    <div className="mt-4 flex space-x-4">
                        <button
                            onClick={() => setEnvironment('development')}
                            className={`px-4 py-2 text-sm font-medium rounded-md ${
                                environment === 'development'
                                    ? 'bg-blue-50 text-blue-700 border border-blue-200'
                                    : 'text-gray-500 hover:text-gray-700'
                            }`}
                        >
                            Development
                        </button>
                        <button
                            onClick={() => setEnvironment('production')}
                            className={`px-4 py-2 text-sm font-medium rounded-md ${
                                environment === 'production'
                                    ? 'bg-blue-50 text-blue-700 border border-blue-200'
                                    : 'text-gray-500 hover:text-gray-700'
                            }`}
                        >
                            Production
                        </button>
                    </div>

                    <div className="mt-4 flex items-center space-x-4 p-4 bg-gray-50 rounded-lg border border-gray-200">
                        <Key className="w-5 h-5 text-gray-500" />
                        <code className="flex-1 font-mono text-sm text-gray-900">
                            {maskApiKey(token)}
                        </code>
                        <div className="flex space-x-2">
                            <button
                                onClick={() => setShowFullKey(!showFullKey)}
                                className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                            >
                                {showFullKey ? 'Hide' : 'Show'}
                            </button>
                            <button
                                onClick={copyToClipboard}
                                className="px-3 py-1.5 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 flex items-center space-x-2"
                            >
                                {copied ? (
                                    <CheckCircle2 className="w-4 h-4" />
                                ) : (
                                    <Copy className="w-4 h-4" />
                                )}
                                <span>{copied ? 'Copied!' : 'Copy'}</span>
                            </button>
                            <button
                                onClick={() => setShowRegenDialog(true)}
                                className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 flex items-center space-x-2"
                            >
                                <RefreshCcw className="w-4 h-4" />
                                <span>Regenerate</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Quick Start Guide */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="p-6">
                    <h2 className="text-2xl font-semibold text-gray-900">Quick Start</h2>
                    <p className="mt-2 text-gray-600">
                        Get started with Botenders API in minutes. Here's a simple example to send a message to your bot.
                    </p>

                    <div className="mt-6 space-y-4">
                        <h3 className="text-lg font-medium text-gray-900">Installation</h3>
                        <CodeSnippet
                            language="bash"
                            code="npm install @botenders/sdk"
                        />

                        <h3 className="text-lg font-medium text-gray-900 mt-6">Usage</h3>
                        <CodeSnippet
                            language="javascript"
                            code={`import { Botenders } from '@botenders/sdk';

const botenders = new Botenders('your_api_key');

// Send a message to your bot
const response = await botenders.chat.send({
    bot_id: 'your_bot_id',
    message: 'Hello, bot!'
});`}
                        />
                    </div>
                </div>
            </div>

            {/* Rate Limits & Usage */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="p-6">
                    <h2 className="text-2xl font-semibold text-gray-900">Rate Limits & Usage</h2>
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                            <div className="font-medium text-gray-900">Requests / Month</div>
                            <div className="mt-2 text-2xl font-semibold text-blue-600">50,000 / 100,000</div>
                            <div className="mt-1 text-sm text-gray-500">Current Usage</div>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                            <div className="font-medium text-gray-900">Rate Limit</div>
                            <div className="mt-2 text-2xl font-semibold text-blue-600">100/min</div>
                            <div className="mt-1 text-sm text-gray-500">Per API Key</div>
                        </div>
                        <div className="p-4 bg-gray-50 rounded-lg border border-gray-200">
                            <div className="font-medium text-gray-900">Response Time</div>
                            <div className="mt-2 text-2xl font-semibold text-blue-600">245ms</div>
                            <div className="mt-1 text-sm text-gray-500">Average</div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Webhooks Configuration */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="p-6">
                    <h2 className="text-2xl font-semibold text-gray-900">Webhooks</h2>
                    <p className="mt-2 text-gray-600">
                        Configure webhooks to receive real-time updates about conversations and events.
                    </p>

                    <div className="mt-6">
                        <div className="flex items-center justify-between">
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Webhook URL</label>
                                <input
                                    type="url"
                                    placeholder="https://your-domain.com/webhook"
                                    className="mt-1 block w-96 rounded-md border border-gray-300 px-3 py-2 text-sm"
                                />
                            </div>
                            <button className="px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700">
                                Save URL
                            </button>
                        </div>

                        <div className="mt-4">
                            <h3 className="text-sm font-medium text-gray-700">Events to receive</h3>
                            <div className="mt-2 space-y-2">
                                {['message.created', 'conversation.started', 'conversation.ended'].map((event) => (
                                    <label key={event} className="flex items-center">
                                        <input type="checkbox" className="rounded border-gray-300 text-blue-600" />
                                        <span className="ml-2 text-sm text-gray-600">{event}</span>
                                    </label>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Resources */}
            <div className="bg-white rounded-lg shadow-sm border border-gray-200">
                <div className="p-6">
                    <h2 className="text-2xl font-semibold text-gray-900">Resources</h2>
                    <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                        <a
                            href="https://api.botenders.com/docs"
                            className="flex items-center p-4 bg-gray-50 rounded-lg border border-gray-200 hover:bg-gray-100"
                        >
                            <Globe className="w-5 h-5 text-gray-500" />
                            <div className="ml-4">
                                <div className="font-medium text-gray-900">API Reference</div>
                                <div className="text-sm text-gray-500">Complete API documentation</div>
                            </div>
                            <ExternalLink className="w-4 h-4 ml-auto text-gray-400" />
                        </a>
                        <a
                            href="https://sdk.botenders.com/docs"
                            className="flex items-center p-4 bg-gray-50 rounded-lg border border-gray-200 hover:bg-gray-100"
                        >
                            <Terminal className="w-5 h-5 text-gray-500" />
                            <div className="ml-4">
                                <div className="font-medium text-gray-900">SDK Documentation</div>
                                <div className="text-sm text-gray-500">Client libraries and examples</div>
                            </div>
                            <ExternalLink className="w-4 h-4 ml-auto text-gray-400" />
                        </a>
                    </div>
                </div>
            </div>

            {/* Regenerate Key Dialog */}
            {showRegenDialog && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                        <div className="flex items-center space-x-2 text-red-600">
                            <AlertCircle className="w-5 h-5" />
                            <h3 className="text-lg font-medium">Regenerate API Key?</h3>
                        </div>
                        <p className="mt-2 text-sm text-gray-600">
                            This action will invalidate your current API key. Make sure to update your applications
                            with the new key to prevent any service interruption.
                        </p>
                        <div className="mt-4 flex space-x-3">
                            <button
                                onClick={() => setShowRegenDialog(false)}
                                className="flex-1 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => {
                                    // Handle regenerate logic
                                    setShowRegenDialog(false);
                                }}
                                className="flex-1 px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-md hover:bg-red-700"
                            >
                                Regenerate
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Developer;