import React, { useState } from 'react';
import {
    ChevronDown,
    Terminal,
    Book,
    Workflow,
    MessageSquare,
    // Code,
    Lock,
    Send
} from 'lucide-react';

const Documentation = ({ openApiSpec }) => {
    const [activeTab, setActiveTab] = useState('overview');
    const [expandedEndpoint, setExpandedEndpoint] = useState(null);

    // Helper function to get HTTP method color
    const getMethodColor = (method) => {
        const colors = {
            get: 'bg-green-100 text-green-800',
            post: 'bg-orange-100 text-orange-800',
            put: 'bg-yellow-100 text-yellow-800',
            delete: 'bg-red-100 text-red-800',
            patch: 'bg-purple-100 text-purple-800'
        };
        return colors[method.toLowerCase()] || 'bg-gray-100 text-gray-800';
    };

    const renderOverview = () => (
        <div className="space-y-6">
            <div className="border-b border-gray-200 pb-6">
                <h1 className="text-3xl font-bold text-gray-900">{openApiSpec?.info?.title || 'API Documentation'}</h1>
                <p className="mt-2 text-gray-600">{openApiSpec?.info?.description || 'Welcome to our API documentation.'}</p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="border rounded-lg p-6 bg-white">
                    <h2 className="flex items-center text-lg font-semibold mb-4">
                        <Lock className="w-5 h-5 mr-2" />
                        Authentication
                    </h2>
                    <p className="text-gray-600">
                        {openApiSpec?.components?.securitySchemes?.apiKey?.description ||
                            'Authenticate your API requests using your API key in the Authorization header.'}
                    </p>
                </div>

                <div className="border rounded-lg p-6 bg-white">
                    <h2 className="flex items-center text-lg font-semibold mb-4">
                        <Send className="w-5 h-5 mr-2" />
                        Base URL
                    </h2>
                    <code className="block p-3 bg-gray-50 rounded text-sm">
                        {openApiSpec?.servers?.[0]?.url || 'https://botenders.com/api'}
                    </code>
                </div>
            </div>
        </div>
    );

    const renderEndpoints = () => (
        <div className="space-y-4">
            {Object.entries(openApiSpec?.paths || {}).map(([path, methods]) => (
                <div key={path} className="border rounded-lg overflow-hidden">
                    {Object.entries(methods).map(([method, details]) => (
                        <div key={`${path}-${method}`} className="border-b last:border-b-0">
                            <button
                                onClick={() => setExpandedEndpoint(
                                    expandedEndpoint === `${path}-${method}` ? null : `${path}-${method}`
                                )}
                                className="w-full px-6 py-4 flex items-center justify-between hover:bg-gray-50"
                            >
                                <div className="flex items-center space-x-4">
                                    <span className={`px-3 py-1 rounded-full text-sm font-medium uppercase ${getMethodColor(method)}`}>
                                        {method}
                                    </span>
                                    <code className="text-gray-900">{path}</code>
                                </div>
                                <ChevronDown
                                    className={`w-5 h-5 text-gray-500 transition-transform
                    ${expandedEndpoint === `${path}-${method}` ? 'rotate-180' : ''}`}
                                />
                            </button>

                            {expandedEndpoint === `${path}-${method}` && (
                                <div className="px-6 py-4 bg-gray-50 border-t border-gray-200 space-y-4">
                                    <div>
                                        <h3 className="text-lg font-medium text-gray-900 mb-2">{details.summary}</h3>
                                        <p className="text-gray-600">{details.description}</p>
                                    </div>

                                    {details.parameters && (
                                        <div>
                                            <h4 className="font-medium text-gray-900 mb-2">Parameters</h4>
                                            <div className="bg-white rounded border">
                                                {details.parameters.map((param, index) => (
                                                    <div key={param.name} className={`p-4 ${index !== 0 ? 'border-t' : ''}`}>
                                                        <div className="flex items-center space-x-2">
                                                            <code className="text-sm font-mono">{param.name}</code>
                                                            <span className="text-xs text-gray-500">({param.in})</span>
                                                            {param.required && (
                                                                <span className="text-xs text-red-500">required</span>
                                                            )}
                                                        </div>
                                                        <p className="mt-1 text-sm text-gray-600">{param.description}</p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}

                                    {details.requestBody && (
                                        <div>
                                            <h4 className="font-medium text-gray-900 mb-2">Request Body</h4>
                                            <div className="bg-white rounded border p-4">
                                                <code className="block whitespace-pre-wrap text-sm">
                                                    {JSON.stringify(details.requestBody, null, 2)}
                                                </code>
                                            </div>
                                        </div>
                                    )}

                                    {details.responses && (
                                        <div>
                                            <h4 className="font-medium text-gray-900 mb-2">Responses</h4>
                                            <div className="bg-white rounded border">
                                                {Object.entries(details.responses).map(([code, response], index) => (
                                                    <div key={code} className={`p-4 ${index !== 0 ? 'border-t' : ''}`}>
                                                        <div className="flex items-center space-x-2 mb-2">
                                                            <code className="text-sm font-mono">{code}</code>
                                                            <span className="text-sm text-gray-600">{response.description}</span>
                                                        </div>
                                                        {response.content && (
                                                            <code className="block whitespace-pre-wrap text-sm bg-gray-50 p-3 rounded">
                                                                {JSON.stringify(response.content, null, 2)}
                                                            </code>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );

    const tabs = [
        { id: 'overview', label: 'Overview', icon: Book, content: renderOverview },
        { id: 'endpoints', label: 'Endpoints', icon: Terminal, content: renderEndpoints },
        { id: 'guides', label: 'Guides', icon: Workflow },
        { id: 'examples', label: 'Examples', icon: MessageSquare },
    ];

    return (
        <div className="max-w-6xl mx-auto p-6">
            {/* Tab Navigation */}
            <div className="border-b border-gray-200 mb-8">
                <nav className="flex space-x-8">
                    {tabs.map(({ id, label, icon: Icon }) => (
                        <button
                            key={id}
                            onClick={() => setActiveTab(id)}
                            className={`
                py-4 px-1 flex items-center space-x-2 border-b-2 font-medium text-sm
                ${activeTab === id
                                    ? 'border-orange-500 text-orange-600'
                                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
              `}
                        >
                            <Icon className="w-5 h-5" />
                            <span>{label}</span>
                        </button>
                    ))}
                </nav>
            </div>

            {/* Content */}
            <div className="mt-6">
                {tabs.find(tab => tab.id === activeTab)?.content?.()}
            </div>
        </div>
    );
};

export default Documentation;