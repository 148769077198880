import React, { useState, useEffect } from 'react';
import {
    Building2,
    Shield,
    Network,
    BarChart3,
    Headphones,
    ArrowRight,
    Mail,
    Mic,
    Image,
    Video,
    Brain,
    Bot,
    Workflow,
    Rocket,
    Database,
    Shapes,
    Binary,
    Map
} from 'lucide-react';

const AnimatedTitle = () => {
    const [mounted, setMounted] = useState(false);
    const firstLine = "Comprehensive AI";
    const secondLine = "Integration for";
    const thirdLine = "Enterprise";
    
    useEffect(() => {
        setMounted(true);
    }, []);

    const AnimatedLine = ({ text, baseDelay = 0 }) => (
        <div className="relative inline-block">
            {text.split('').map((char, idx) => (
                <span
                    key={idx}
                    className={`
                        relative 
                        inline-block 
                        text-red-600
                        opacity-0
                        whitespace-pre
                        ${mounted ? 'animate-pop-in' : ''}
                    `}
                    style={{
                        animationDelay: `${baseDelay + (idx * 50)}ms`,
                        animationFillMode: 'forwards'
                    }}
                >
                    {char === ' ' ? '\u00A0' : char}
                </span>
            ))}
            <div className={`
                absolute 
                -inset-1 
                bg-gradient-to-r 
                from-red-200/0 
                via-red-200/50 
                to-red-200/0
                opacity-0
                ${mounted ? 'animate-shine' : ''}
                [animation-delay:${baseDelay + 1000}ms]
                [animation-fill-mode:forwards]
            `} />
        </div>
    );

    return (
        <h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-6xl max-w-4xl mx-auto px-4">
            <AnimatedLine text={firstLine} baseDelay={0} />
            <br />
            <AnimatedLine text={secondLine} baseDelay={800} />
            <br />
            <AnimatedLine text={thirdLine} baseDelay={1600} />
        </h1>
    );
};
const Enterprise = () => {
    const features = [
        {
            icon: Bot,
            title: "AI Module Integration",
            description: "Seamlessly integrate and combine multiple AI capabilities from language processing to spatial computing and multimodal interactions."
        },
        {
            icon: Shield,
            title: "Enterprise Security",
            description: "SOC 2 Type II certified. Custom security policies, audit logs, and data encryption at rest and in transit."
        },
        {
            icon: Workflow,
            title: "Custom Workflows",
            description: "Design and implement custom AI pipelines combining multiple models and services into sophisticated solutions."
        },
        {
            icon: Network,
            title: "Unified API Access",
            description: "Single API interface to access and orchestrate multiple AI services through our enterprise-grade integration layer."
        },
        {
            icon: BarChart3,
            title: "Advanced Analytics",
            description: "Comprehensive monitoring and analytics across all AI services, with cost and performance optimization."
        },
        {
            icon: Headphones,
            title: "Priority Support",
            description: "24/7 dedicated support team, technical account manager, and guaranteed response times."
        }
    ];

    const aiCapabilities = [
        {
            icon: Brain,
            title: "Language AI",
            features: [
                "Custom LLM deployments",
                "Specialized model fine-tuning",
                "Multi-language support",
                "Domain adaptation",
                "Contextual understanding"
            ]
        },
        {
            icon: Database,
            title: "Vector Database",
            features: [
                "Large-scale vector storage",
                "Semantic search",
                "Real-time indexing",
                "Hybrid search capabilities",
                "Multi-modal embeddings"
            ]
        },
        {
            icon: Mic,
            title: "Voice & Speech",
            features: [
                "Speech recognition",
                "Voice synthesis",
                "Real-time transcription",
                "Voice cloning",
                "Emotion detection"
            ]
        },
        {
            icon: Image,
            title: "Vision & Image",
            features: [
                "Image recognition",
                "Object detection",
                "Visual search",
                "Image generation",
                "Style transfer"
            ]
        },
        {
            icon: Video,
            title: "Video Processing",
            features: [
                "Video analysis",
                "Motion detection",
                "Video generation",
                "Real-time processing",
                "Video summarization"
            ]
        },
        {
            icon: Shapes,
            title: "3D & Spatial",
            features: [
                "3D model generation",
                "Spatial recognition",
                "AR/VR integration",
                "Scene understanding",
                "Point cloud processing"
            ]
        },
        {
            icon: Binary,
            title: "Decision Systems",
            features: [
                "Automated reasoning",
                "Expert systems",
                "Risk assessment",
                "Recommendation engines",
                "Anomaly detection"
            ]
        },
        {
            icon: Map,
            title: "Knowledge Graphs",
            features: [
                "Entity relationship mapping",
                "Ontology management",
                "Knowledge extraction",
                "Reasoning engines",
                "Graph neural networks"
            ]
        }
    ];

    const customizations = [
        "Multi-modal AI integration",
        "Custom model development",
        "Private infrastructure deployment",
        "Specialized AI pipelines",
        "Custom API endpoints",
        "On-premises solutions",
        "Data privacy controls",
        "Custom vector stores"
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-slate-50 via-red-50 to-slate-50">
            {/* Hero Section */}
            <section className="px-4 py-20">
                <div className="mx-auto max-w-6xl">
                    <div className="text-center">
                        <div className="mb-6 inline-flex items-center gap-2 rounded-full bg-red-100 px-4 py-2">
                            <Building2 className="h-5 w-5 text-red-600" />
                            <span className="text-red-800 font-medium">Enterprise Solutions</span>
                        </div>
                        <AnimatedTitle />
                        <p className="mx-auto mt-6 max-w-2xl text-xl text-slate-600">
                            Orchestrate and combine multiple AI capabilities - from language and vision to knowledge graphs and vector databases - into powerful custom solutions tailored for your enterprise needs.
                        </p>
                        
                        <div className="mt-8 flex flex-col items-center justify-center gap-4 sm:flex-row">
                            <a
                                href="/contact"
                                className="group relative flex items-center gap-2 rounded-xl bg-red-600 px-8 py-4 font-semibold text-white transition-all hover:bg-red-700 hover:-translate-y-1"
                            >
                                Contact Sales
                                <Mail className="h-5 w-5" />
                            </a>
                            <a
                                href="/documentation/enterprise"
                                className="flex items-center gap-2 rounded-xl border border-slate-200 bg-white px-6 py-4 font-semibold text-slate-700 transition-all hover:border-red-200 hover:bg-red-50 hover:-translate-y-1"
                            >
                                Learn More
                                <ArrowRight className="h-5 w-5" />
                            </a>
                        </div>
                    </div>

                    {/* Enterprise Stats */}
                    <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3">
                        {[
                            { label: "AI Models & Modules", value: "100+" },
                            { label: "SLA Uptime", value: "99%" },
                            { label: "Language Supported", value: "50+" }
                        ].map((stat, index) => (
                            <div key={index} 
                                className="rounded-2xl border border-slate-200 bg-white p-6 text-center shadow-sm transition-all hover:shadow-lg hover:-translate-y-1"
                            >
                                <div className="text-2xl font-bold text-red-600">
                                    {stat.value}
                                </div>
                                <div className="mt-2 text-slate-600">{stat.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>


            {/* AI Capabilities Grid */}
            <section className="px-4 py-16 bg-white/50">
                <div className="mx-auto max-w-6xl">
                    <h2 className="text-3xl font-bold text-center text-slate-900 mb-4">
                        Advanced AI Modules
                    </h2>
                    <p className="text-xl text-center text-slate-600 mb-12">
                        Combine and integrate multiple AI technologies into unified enterprise solutions
                    </p>
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-4">
                        {aiCapabilities.map((capability, index) => (
                            <div key={index} 
                                className="group rounded-xl border border-slate-200 bg-white p-6 shadow-sm transition-all hover:border-red-200 hover:shadow-md"
                            >
                                <capability.icon className="h-12 w-12 text-red-600" />
                                <h3 className="mt-4 text-xl font-semibold text-slate-800">
                                    {capability.title}
                                </h3>
                                <ul className="mt-4 space-y-2">
                                    {capability.features.map((feature, fIndex) => (
                                        <li key={fIndex} className="flex items-center gap-2 text-slate-600">
                                            <div className="h-1.5 w-1.5 rounded-full bg-red-600" />
                                            {feature}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Integration Features */}
            <section className="px-4 py-16">
                <div className="mx-auto max-w-6xl">
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {features.map((feature, index) => (
                            <div key={index} 
                                className="group rounded-xl border border-slate-200 bg-white p-6 shadow-sm transition-all hover:border-red-200 hover:shadow-md"
                            >
                                <feature.icon className="h-12 w-12 text-red-600" />
                                <h3 className="mt-4 text-xl font-semibold text-slate-800">
                                    {feature.title}
                                </h3>
                                <p className="mt-2 text-slate-600">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Custom Solutions */}
            <section className="px-4 py-16">
                <div className="mx-auto max-w-6xl">
                    <div className="rounded-2xl border border-slate-200 bg-white p-8 shadow-sm">
                        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-between">
                            <div className="lg:max-w-2xl">
                                <h2 className="text-3xl font-bold text-slate-900">
                                    Customized AI Solutions
                                </h2>
                                <p className="mt-4 text-lg text-slate-600">
                                    We work with you to design and implement custom AI solutions that combine multiple capabilities into unified workflows tailored to your specific needs.
                                </p>
                                <div className="mt-8 grid grid-cols-1 gap-4 sm:grid-cols-2">
                                    {customizations.map((item, index) => (
                                        <div key={index} className="flex items-center gap-2 text-slate-700">
                                            <div className="h-1.5 w-1.5 rounded-full bg-red-600" />
                                            {item}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="mt-8 lg:mt-0">
                                <Rocket className="h-32 w-32 text-red-600 opacity-80" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Contact CTA */}
            <section className="px-4 py-20">
                <div className="mx-auto max-w-4xl rounded-2xl bg-gradient-to-r from-red-600 to-red-700 p-8 text-center shadow-xl">
                    <h2 className="text-3xl font-bold text-white">
                        Ready to Transform Your AI Capabilities?
                    </h2>
                    <p className="mt-4 text-lg text-red-100">
                        Let's discuss how our unified AI integration platform can work for your enterprise
                    </p>
                    <div className="mt-8 flex flex-col items-center justify-center gap-4 sm:flex-row">
                        <a
                            href="/contact"
                            className="group flex items-center gap-2 rounded-xl bg-white px-8 py-4 font-semibold text-red-600 transition-all hover:bg-red-50"
                        >
                            Contact Sales
                            <ArrowRight className="h-5 w-5 transition-transform group-hover:translate-x-1" />
                        </a>
                    </div>
                    <p className="mt-4 text-sm text-red-100">
                        Typically responds within 24 hours
                    </p>
                </div>
            </section>

            <style jsx>{`
                @keyframes popIn {
                    0% {
                        transform: scale(0.8);
                        opacity: 0;
                    }
                    70% {
                        transform: scale(1.1);
                        opacity: 0.7;
                    }
                    100% {
                        transform: scale(1);
                        opacity: 1;
                    }
                }

                @keyframes shine {
                    0% {
                        opacity: 0;
                        transform: translateX(-100%);
                    }
                    50% {
                        opacity: 1;
                    }
                    100% {
                        opacity: 0;
                        transform: translateX(100%);
                    }
                }

                .animate-pop-in {
                    animation: popIn 0.5s ease-out;
                }

                .animate-shine {
                    animation: shine 2s ease-in-out;
                }
            `}</style>
        </div>
    );
};

export default Enterprise;