import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyD1F7OoAxz-jTtGfFdUDhVyW0GMbag2XQI",
    // authDomain: "virtual-muse-437519-u7.firebaseapp.com",
    authDomain: "botenders.com",
    projectId: "virtual-muse-437519-u7",
    storageBucket: "virtual-muse-437519-u7.appspot.com",
    messagingSenderId: "58188367167",
    appId: "1:58188367167:web:ec68f95cd0a22790b7ff8a",
    measurementId: "G-Z8QHHZ8190"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app);

export { auth, analytics };