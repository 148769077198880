import React from 'react';
import { MessageCircle, GitBranch, Calendar, Brain, Code, FileSearch, PenTool, BookOpen } from 'lucide-react';

const UseCase = ({ icon: Icon, title, description }) => (
    <div className="flex gap-6 rounded-xl bg-white/80 p-6 backdrop-blur-sm transition-all hover:bg-white/90">
        <div className="inline-flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-xl bg-purple-100">
            <Icon className="h-6 w-6 text-purple-600" />
        </div>
        <div>
            <h3 className="font-mono text-lg font-bold text-gray-900">{title}</h3>
            <p className="mt-2 text-sm leading-relaxed text-gray-600">{description}</p>
        </div>
    </div>
);

const UseCasesSection = () => {
    const useCases = [
        {
            icon: Brain,
            title: "Personal AI Assistant",
            description: "Your AI companion that learns your preferences, habits, and working style to provide personalized support throughout your day."
        },
        {
            icon: Code,
            title: "Intelligent Coding",
            description: "AI pair programmer that understands your codebase, suggests improvements, and helps debug issues in your preferred coding style."
        },
        {
            icon: MessageCircle,
            title: "Enhanced Communication",
            description: "Context-aware communication assistant that helps draft emails, messages, and documents in your voice and tone."
        },
        {
            icon: FileSearch,
            title: "Smart Knowledge Base",
            description: "Personal AI that organizes and connects your notes, documents, and research, making everything instantly accessible."
        },
        {
            icon: GitBranch,
            title: "Workflow Automation",
            description: "AI-powered automation that learns your workflows and proactively suggests ways to optimize your daily tasks."
        },
        {
            icon: PenTool,
            title: "Creative Support",
            description: "AI creative partner that helps brainstorm ideas, refine designs, and enhance your creative process with your style preferences."
        },
        {
            icon: Calendar,
            title: "Intelligent Time Management",
            description: "Personal scheduler that learns your productivity patterns to optimize your calendar and manage your time effectively."
        },
        {
            icon: BookOpen,
            title: "Personalized Learning",
            description: "Adaptive learning companion that tailors educational content to your learning style and knowledge gaps."
        }
    ];

    return (
        <div className="relative min-h-screen w-full overflow-hidden bg-gradient-to-br from-white via-purple-50 to-white pt-16">
            {/* Background Elements */}
            <div className="absolute inset-0 overflow-hidden">
                <div className="absolute -right-24 top-1/4 h-96 w-96 rounded-full bg-purple-100/30 blur-3xl" />
                <div className="absolute -left-24 top-3/4 h-96 w-96 rounded-full bg-orange-100/30 blur-3xl" />
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-96 w-96 rounded-full bg-purple-100/20 blur-3xl" />
            </div>

            {/* Content */}
            <div className="relative mx-auto flex min-h-[calc(100vh-4rem)] max-w-6xl flex-col justify-center px-4 py-16 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center gap-12">
                    {/* Header Section */}
                    <div className="text-center">
                        <h2 className="font-mono text-lg text-gray-800">
                            Personal AI Assistant
                        </h2>
                        <h1 className="mt-2 font-mono text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
                            Your Intelligent
                            <span className="relative mx-2 whitespace-nowrap">
                                Companion
                                <span className="absolute -bottom-1 left-0 h-1 w-full bg-purple-200" />
                            </span>
                        </h1>
                        <p className="mt-4 max-w-2xl text-gray-600">
                            An AI that adapts to your unique way of working, thinking, and creating
                        </p>
                    </div>

                    {/* Use Cases */}
                    <div className="grid w-full gap-4 md:grid-cols-2">
                        {useCases.map((useCase, index) => (
                            <UseCase key={index} {...useCase} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UseCasesSection;