import React from "react";
import {
    Cloud,
    Terminal,
    Server,
    Shield,
    Zap,
    Layout,
    Globe,
    Users,
    BarChart3,
    Headphones,
    ArrowRight
} from "lucide-react";

const Products = () => {
    const products = [
        {
            icon: Cloud,
            title: "Cloud-Native",
            description: "Build and deploy AI applications with our powerful cloud infrastructure."
        },
        {
            icon: Globe,
            title: "Global Edge Network",
            description: "Deploy your models closer to your users with our distributed infrastructure."
        },
        {
            icon: Layout,
            title: "Studio Interface",
            description: "Intuitive dashboard for managing your AI deployments and monitoring performance."
        },
        {
            icon: Terminal,
            title: "Full API Access",
            description: "Comprehensive API with WebSocket support for real-time applications."
        },
        {
            icon: Server,
            title: "Unlimited API Access",
            description: "No rate limits. Scale your AI operations with dedicated infrastructure and priority processing."
        },
        {
            icon: Shield,
            title: "Enterprise Security",
            description: "SOC 2 Type II certified. Custom security policies, audit logs, and compliance reporting."
        },
        {
            icon: Users,
            title: "Team Management",
            description: "Advanced user roles, SSO integration, and centralized administration console."
        },
        {
            icon: BarChart3,
            title: "Advanced Analytics",
            description: "Detailed usage metrics, cost allocation, and performance analytics across your organization."
        },
        {
            icon: Headphones,
            title: "Priority Support",
            description: "24/7 dedicated support team, technical account manager, and guaranteed response times."
        },
        {
            icon: Zap,
            title: "Real-time Analytics",
            description: "Monitor performance, costs, and usage patterns in real-time."
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-slate-50 via-orange-50 to-slate-50">
            {/* Hero Section */}
            <section className="px-4 py-20 text-center">
                <div className="mx-auto max-w-6xl">
                    <div className="mb-6 inline-block rounded-full bg-orange-500/10 px-4 py-2 text-orange-400">
                        Our Products
                    </div>
                    <h1 className="text-4xl font-bold tracking-tight text-slate-900 sm:text-6xl">
                        AI Solutions for Every Need
                    </h1>
                    <p className="mx-auto mt-6 max-w-2xl text-xl text-slate-600">
                        Explore our comprehensive suite of AI-powered products designed to empower businesses of all sizes.
                    </p>
                </div>
            </section>

            {/* Products Grid */}
            <section className="px-4 py-16">
                <div className="mx-auto max-w-6xl">
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {products.map((product, index) => (
                            <div key={index} className="group rounded-xl border border-slate-200 bg-white/50 p-6 backdrop-blur-sm transition-all hover:border-orange-200 hover:bg-white/80">
                                <product.icon className="h-12 w-12 text-orange-500 transition-transform group-hover:scale-110" />
                                <h3 className="mt-4 text-xl font-semibold text-slate-700">
                                    {product.title}
                                </h3>
                                <p className="mt-2 text-slate-600">{product.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Final CTA */}
            <section className="px-4 py-20">
                <div className="mx-auto max-w-4xl rounded-2xl border border-slate-200 bg-white/50 p-8 text-center backdrop-blur-sm">
                    <div className="inline-flex items-center gap-2 rounded-full bg-orange-100 px-4 py-2">
                        <span className="text-orange-700 font-medium">Get in Touch</span>
                    </div>
                    <h2 className="mt-4 text-3xl font-bold text-slate-700">
                        Ready to Transform Your Business with AI?
                    </h2>
                    <p className="mt-4 text-lg text-slate-600">
                        Contact us to learn more about how our products can be customized to fit your business needs.
                    </p>
                    <div className="mt-8 flex flex-col items-center justify-center gap-4 sm:flex-row">
                        <a href="/contact" className="group flex items-center gap-2 rounded-xl bg-gradient-to-r from-orange-500 to-indigo-500 px-8 py-4 font-semibold text-white transition-all hover:from-orange-600 hover:to-indigo-600">
                            Contact Us
                            <ArrowRight className="h-5 w-5 transition-transform group-hover:translate-x-1" />
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Products;