import "./PrivacyNotice.scss";
import React from "react";

const PrivacyNotice = () => {
    return (
        <div className="PrivacyNotice">
            <div className="PrivacyNotice__content">
                <h1>Privacy Notice</h1>
                <p>
                    At Botenders Inc., your privacy is of utmost importance to us. This notice outlines our commitment to your privacy and how we collect, use, and protect your information.
                </p>
                <h2>Information Collection</h2>
                <p>
                    We collect information that you provide to us when you use our services. This may include your name, email address, and any other details you submit.
                </p>
                <h2>Usage of Information</h2>
                <p>
                    The information we collect is used to improve your experience with our services. We do not share your personal data with third parties without your consent.
                </p>
                <h2>Data Security</h2>
                <p>
                    We implement strict measures to ensure the security of your information. Unauthorized access, use, or disclosure is prevented by using secure technology.
                </p>
            </div>
        </div>
    );
}

export default PrivacyNotice;