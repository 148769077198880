import React, { useState, useCallback, useRef, useEffect } from 'react';
import { X, MessageCircle, ChevronLeft, ChevronRight, Send, Loader2, Coffee } from 'lucide-react';

const INITIAL_MESSAGE = {
    role: 'assistant',
    content: "🍸 Hi! I'm Mixie, your personal AI assistant from Botenders. How can I shake things up for you today?"
};

const MessageInput = ({ onSend, disabled }) => {
    const [inputValue, setInputValue] = useState('');

    const handleSend = useCallback(() => {
        if (inputValue.trim()) {
            onSend(inputValue.trim());
            setInputValue('');
        }
    }, [inputValue, onSend]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    }, [handleSend]);

    const handleInputChange = useCallback((e) => {
        setInputValue(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${Math.min(e.target.scrollHeight, 150)}px`;
    }, []);

    return (
        <div className="flex gap-3 p-4 border-t border-gray-200/30 bg-white/80 backdrop-blur-lg">
            <textarea
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Type your message..."
                className="flex-1 resize-none rounded-2xl border border-gray-200/50 bg-white/90 px-4 py-3 
                          shadow-sm transition-all duration-200 placeholder:text-gray-400
                          focus:border-indigo-500 focus:bg-white focus:outline-none focus:ring-2 
                          focus:ring-indigo-500/20 disabled:cursor-not-allowed disabled:opacity-50"
                rows={1}
                disabled={disabled}
                style={{ overflow: 'hidden' }}
            />
            <button 
                onClick={handleSend}
                disabled={disabled || !inputValue.trim()}
                className="flex h-12 w-12 items-center justify-center rounded-full bg-indigo-500 
                          shadow-lg transition-all duration-200 hover:bg-indigo-600 
                          disabled:cursor-not-allowed disabled:opacity-50
                          hover:scale-105 active:scale-95 hover:shadow-indigo-500/20"
            >
                <Send className="h-5 w-5 text-white" />
            </button>
        </div>
    );
};

// Update the MessageBubble component's colors
const MessageBubble = ({ message }) => (
    <div className={`group mb-4 flex ${message.role === 'assistant' ? 'justify-start' : 'justify-end'} 
                    animate-fadeIn`}>
        <div className={`max-w-[80%] rounded-2xl px-4 py-3 shadow-sm transition-all duration-200
            ${message.role === 'assistant'
                ? 'bg-white/90 backdrop-blur-sm text-gray-800 hover:bg-white'
                : 'bg-amber-500 text-white hover:bg-amber-600'
            } group-hover:shadow-md`}>
            <p className="whitespace-pre-wrap break-words">{message.content}</p>
        </div>
    </div>
);

const TypingIndicator = () => (
    <div className="mb-4 flex justify-start">
        <div className="max-w-[80%] rounded-2xl bg-white/90 backdrop-blur-sm px-4 py-3 shadow-sm">
            <div className="flex gap-1">
                <Loader2 className="h-5 w-5 animate-spin text-amber-500" />
                <span className="text-sm text-gray-500">Mixing up a response...</span>
            </div>
        </div>
    </div>
);

const ChatContent = ({ messages, isTyping, onSend, onToggle, isMobile, isExpanded }) => {
    const messagesEndRef = useRef(null);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages, isTyping]);

    return (
        <div className="flex flex-col h-full relative overflow-hidden">
            {/* Animated Background */}
            <div className="absolute inset-0 bg-gradient-to-br from-amber-50 via-orange-50 to-rose-50">
                <div className="absolute inset-0 bg-grid-amber/[0.03] bg-[size:20px_20px]" />
                <div className="absolute inset-0">
                    <div className="absolute top-1/4 left-1/4 -translate-x-1/2 -translate-y-1/2 w-64 h-64 
                                  rounded-full bg-amber-500/10 blur-3xl animate-blob" />
                    <div className="absolute top-3/4 right-1/4 translate-x-1/2 -translate-y-1/2 w-64 h-64 
                                  rounded-full bg-orange-500/10 blur-3xl animate-blob animation-delay-2000" />
                    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-64 h-64 
                                  rounded-full bg-rose-500/10 blur-3xl animate-blob animation-delay-4000" />
                </div>
            </div>

            <div className="p-4 border-b border-gray-200/30 bg-white/80 backdrop-blur-lg flex justify-between items-center relative z-10">
                <div className="flex items-center gap-3">
                    <Coffee className="h-5 w-5 text-amber-600" />
                    <div className="flex flex-col">
                        <h2 className="font-semibold text-gray-800">Mixie</h2>
                        <span className="text-xs text-amber-600">by Botenders</span>
                    </div>
                </div>
                <div className="flex items-center gap-2">
                    <div className="h-2 w-2 rounded-full bg-amber-500 animate-pulse" />
                    <button
                        onClick={onToggle}
                        className="p-2 hover:bg-amber-100/80 rounded-full transition-colors duration-200"
                    >
                        {isMobile ? 
                            (isExpanded ? <X className="w-5 h-5" /> : <MessageCircle className="w-5 h-5" />) :
                            <ChevronRight className="w-5 h-5 text-gray-600" />
                        }
                    </button>
                </div>
            </div>

            {/* Rest of the ChatContent component remains the same but with amber/orange colors instead of indigo */}
            <div className="flex-1 overflow-y-auto p-4 relative z-10">
                <div className="space-y-4">
                    {messages.map((message, index) => (
                        <MessageBubble key={index} message={message} />
                    ))}
                    {isTyping && <TypingIndicator />}
                    <div ref={messagesEndRef} />
                </div>
            </div>
            <MessageInput onSend={onSend} disabled={isTyping} />
        </div>
    );
};

const MinimizedButton = ({ onClick }) => (
    <button
        onClick={onClick}
        className="w-full h-12 flex items-center justify-center relative overflow-hidden
                 hover:bg-gray-100/10 transition-colors duration-200 group"
    >
        {/* Minimized Button Background */}
        <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-pink-500/20">
            <div className="absolute inset-0 bg-grid-indigo/[0.03] bg-[size:20px_20px]" />
            <div className="absolute -inset-[100%] animate-[spin_8s_linear_infinite] bg-gradient-conic-to-l 
                          from-indigo-500/10 via-purple-500/10 to-transparent blur-xl" />
        </div>
        <ChevronLeft className="w-6 h-6 text-gray-600 relative z-10 transition-transform duration-200 
                               group-hover:scale-110" />
    </button>
);

const ChatWindow = ({ isExpanded, onToggle, isMobile, sessionId }) => {
    const [messages, setMessages] = useState([INITIAL_MESSAGE]);
    const [isTyping, setIsTyping] = useState(false);

    const fetchResponse = useCallback(async (userInput) => {
        try {
            const response = await fetch('/api/public/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    message: userInput,
                    session_id: sessionId,
                })
            });

            if (response.ok) {
                const data = await response.json();
                return data.message;
            }

            if (response.status === 429) {
                const data = await response.json();
                const minutesUntilReset = Math.ceil(data.time_until_reset / 60);
                return `Seems like you've hit your usage limit for this hour. Let's chat later in about ${minutesUntilReset} minute${minutesUntilReset === 1 ? '' : 's'}.`;
            }

            throw new Error('Failed to fetch response');
        } catch (error) {
            console.error('Error fetching response:', error);
            return "Whoops! Something went wrong. Please try again in a moment.";
        }
    }, [sessionId]);

    const handleSendMessage = useCallback(async (message) => {
        setMessages(prev => [...prev, { role: 'user', content: message }]);
        setIsTyping(true);

        const response = await fetchResponse(message);
        setIsTyping(false);
        setMessages(prev => [...prev, { role: 'assistant', content: response }]);
    }, [fetchResponse]);

    return (
        <div className={`
            ${isMobile
                ? `fixed bottom-0 left-0 right-0 bg-white/80 shadow-xl transition-transform duration-300 
                    rounded-t-2xl border-t border-gray-200/30
                    ${isExpanded ? 'translate-y-0' : 'translate-y-[calc(100%-3.5rem)]'}`
                : `h-[calc(100vh-4rem)] mt-16 bg-white/80 border-l border-gray-200/30 
                    transition-all duration-300 shadow-lg backdrop-blur-lg
                    ${isExpanded ? 'w-96' : 'w-12'}`
            }
        `}>
            {isMobile ? (
                <div className="h-[70vh]">
                    <ChatContent 
                        messages={messages}
                        isTyping={isTyping}
                        onSend={handleSendMessage}
                        onToggle={onToggle}
                        isMobile={isMobile}
                        isExpanded={isExpanded}
                    />
                </div>
            ) : (
                !isExpanded ? (
                    <MinimizedButton onClick={onToggle} />
                ) : (
                    <ChatContent 
                        messages={messages}
                        isTyping={isTyping}
                        onSend={handleSendMessage}
                        onToggle={onToggle}
                        isMobile={isMobile}
                        isExpanded={isExpanded}
                    />
                )
            )}
        </div>
    );
};

export default ChatWindow;