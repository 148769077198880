import React, { useState } from 'react';
import {
    Zap,
    Laptop,
    Puzzle,
    ArrowRight,
    Terminal,
    Check,
    Brain,
    Lock
} from 'lucide-react';
import AnimatedHeader from './AnimatedHeader';

const ProductTeaser = () => {
    const [email, setEmail] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleWaitlistSignup = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        setEmail('');
    };

    return (
        <div className="min-h-screen bg-gradient-to-b from-slate-900 to-slate-800 text-white">
            {/* Hero Section */}
            <section className="px-4 py-20">
                <div className="mx-auto max-w-6xl">
                    <div className="text-center">
                        <div className="mb-6 inline-block rounded-full bg-red-500/10 px-4 py-2 text-red-400">
                            The Future of Personal AI
                        </div>
                        <AnimatedHeader />
                        <p className="mx-auto mt-6 max-w-2xl text-xl text-slate-300">
                            Experience the power of a truly personal AI that lives on your computer.
                            Fast, private, and completely adapted to your way of working.
                        </p>

                        {/* Waitlist Form */}
                        <div className="mt-8">
                            {!isSubmitted ? (
                                <form onSubmit={handleWaitlistSignup} className="mx-auto flex max-w-md flex-col gap-4 sm:flex-row">
                                    <input
                                        type="email"
                                        placeholder="Enter your email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        className="flex-1 rounded-xl border border-slate-700 bg-slate-800 px-4 py-3 text-slate-200 placeholder-slate-400 focus:border-red-500 focus:outline-none"
                                        required
                                    />
                                    <button
                                        type="submit"
                                        className="rounded-xl bg-red-500 px-6 py-3 font-semibold text-white transition-all hover:bg-red-600"
                                    >
                                        Join Waitlist
                                    </button>
                                </form>
                            ) : (
                                <div className="mx-auto max-w-md rounded-xl bg-red-500/10 p-4 text-red-400">
                                    <div className="flex items-center justify-center gap-2">
                                        <Check className="h-5 w-5" />
                                        <span>You're on the list! We'll notify you when we launch.</span>
                                    </div>
                                </div>
                            )}
                            <p className="mt-4 text-sm text-slate-400">
                                Shape the future of personal AI computing
                            </p>
                        </div>
                    </div>

                    {/* Stats */}
                    <div className="mt-16 grid grid-cols-1 gap-8 sm:grid-cols-3">
                        {[
                            { label: "More Personal Than Cloud", value: "100%" },
                            { label: "Faster Than Cloud", value: "10x" },
                            { label: "Your Data Stays Local", value: "100%" }
                        ].map((stat, index) => (
                            <div key={index} className="rounded-2xl border border-slate-700 bg-slate-800/50 p-6 text-center">
                                <div className="text-3xl font-bold text-red-400">{stat.value}</div>
                                <div className="mt-2 text-slate-400">{stat.label}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Features Grid */}
            <section className="px-4 py-16">
                <div className="mx-auto max-w-6xl">
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {[
                            {
                                icon: Brain,
                                title: "Truly Personal",
                                description: "An AI that learns your preferences, adapts to your style, and grows with you - all while staying on your machine."
                            },
                            {
                                icon: Lock,
                                title: "Your Data, Your AI",
                                description: "Everything stays on your computer. Your personal data helps train your personal AI, making it uniquely yours."
                            },
                            {
                                icon: Zap,
                                title: "Instant Responses",
                                description: "Lightning-fast local processing means your personal AI assistant responds at the speed of thought."
                            },
                            {
                                icon: Terminal,
                                title: "Power User Ready",
                                description: "Full CLI support, customizable shortcuts, and extensive API for crafting your perfect workflow."
                            },
                            {
                                icon: Puzzle,
                                title: "Seamless Integration",
                                description: "Your personal AI works wherever you do - VSCode, Slack, Notion, and more."
                            },
                            {
                                icon: Laptop,
                                title: "Resource Efficient",
                                description: "Optimized to run smoothly on your laptop, automatically adjusting to your hardware."
                            }
                        ].map((feature, index) => (
                            <div key={index} className="rounded-xl border border-slate-700 bg-slate-800/50 p-6 transition-all hover:border-red-500/50">
                                <feature.icon className="h-12 w-12 text-red-400" />
                                <h3 className="mt-4 text-xl font-semibold text-slate-200">
                                    {feature.title}
                                </h3>
                                <p className="mt-2 text-slate-400">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Integration Showcase */}
            <section className="px-4 py-16">
                <div className="mx-auto max-w-6xl">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold text-slate-200">
                            Your Personal AI, Everywhere You Work
                        </h2>
                        <p className="mt-4 text-slate-400">
                            Seamlessly integrates with all your favorite tools
                        </p>
                    </div>

                    <div className="mt-12 grid grid-cols-2 gap-8 md:grid-cols-4">
                        {['VSCode', 'Slack', 'GitHub', 'Notion', 'Chrome', 'Terminal', 'Discord', 'Obsidian'].map((tool) => (
                            <div key={tool} className="flex items-center justify-center rounded-lg border border-slate-700 bg-slate-800/50 p-4">
                                <span className="text-lg font-medium text-slate-300">{tool}</span>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* CTA Section */}
            <section className="px-4 py-20">
                <div className="mx-auto max-w-4xl text-center">
                    <h2 className="text-3xl font-bold text-slate-200">
                        Ready for Your Personal AI Journey?
                    </h2>
                    <p className="mt-4 text-lg text-slate-400">
                        Join the waitlist to be among the first to experience truly personal AI
                    </p>
                    {!isSubmitted ? (
                        <form onSubmit={handleWaitlistSignup} className="mt-8 flex flex-col items-center gap-4">
                            <input
                                type="email"
                                placeholder="Enter your email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="w-full max-w-md rounded-xl border border-slate-700 bg-slate-800 px-4 py-3 text-slate-200 placeholder-slate-400 focus:border-red-500 focus:outline-none"
                                required
                            />
                            <button
                                type="submit"
                                className="flex items-center justify-center gap-2 rounded-xl bg-red-500 px-8 py-4 font-semibold text-white transition-all hover:bg-red-600"
                            >
                                Join Waitlist
                                <ArrowRight className="h-5 w-5" />
                            </button>
                        </form>
                    ) : (
                        <div className="mt-8 rounded-xl bg-red-500/10 p-4 text-red-400">
                            <div className="flex items-center justify-center gap-2">
                                <Check className="h-5 w-5" />
                                <span>You're on the list! We'll notify you when we launch.</span>
                            </div>
                        </div>
                    )}
                    <p className="mt-4 text-sm text-slate-500">
                        Early access coming soon
                    </p>
                </div>
            </section>
        </div>
    );
};

export default ProductTeaser;