import React from 'react';
import CookieConsent from 'react-cookie-consent';

const Consent = () => {
    return (
        <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            enableDeclineButton
            onAccept={() => {
                // Handle the accept action
            }}
            onDecline={() => {
                // Handle the decline action
            }}
            cookieName="test"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
        >
            This website uses cookies to enhance the user experience.
        </CookieConsent>
    );
};

export default Consent;