import React, { useState, useRef, useEffect } from 'react';
import WelcomeSection from './pages/Welcome';
import FeaturesSection from './pages/Features';
import UseCaseSection from './pages/UseCases';
import ChatWindow from './pages/ChatWindow';

const ScrollStyles = () => (
    <style jsx global>{`
        .hide-scrollbar {
            scrollbar-width: none;
            -ms-overflow-style: none;
        }
        .hide-scrollbar::-webkit-scrollbar {
            display: none;
        }
        .safe-bottom {
            padding-bottom: env(safe-area-inset-bottom);
        }
        .section-container {
            height: 100vh;
            height: 100dvh;
        }
        @supports (-webkit-touch-callout: none) {
            .section-container {
                height: -webkit-fill-available;
            }
        }
    `}</style>
);

const sections = [
    {
        id: 'welcome',
        label: 'Welcome',
        Component: WelcomeSection
    },
    {
        id: 'features',
        label: 'Features',
        Component: FeaturesSection
    },
    {
        id: 'pricing',
        label: 'Pricing',
        Component: UseCaseSection
    }
];

const PageIndicator = ({ currentSection, setCurrentSection }) => (
    <div className="fixed left-8 top-1/2 -translate-y-1/2 z-10 hidden lg:block">
        <div className="flex flex-col gap-4">
            {sections.map((section, idx) => (
                <button
                    key={section.id}
                    onClick={() => {
                        const element = document.getElementById(section.id);
                        element?.scrollIntoView({ behavior: 'smooth' });
                        setCurrentSection(idx);
                    }}
                    className="group relative flex items-center gap-3"
                >
                    <div className={`
                        h-2 rounded-full transition-all duration-300
                        ${idx === currentSection
                            ? 'w-8 bg-violet-600'
                            : 'w-2 bg-gray-400 group-hover:bg-violet-600'}
                    `} />
                    <span className="absolute left-8 text-sm text-gray-600 opacity-0 transition-opacity group-hover:opacity-100 whitespace-nowrap">
                        {section.label}
                    </span>
                </button>
            ))}
        </div>
    </div>
);

const Home = ({ sessionId }) => {
    const [isChatExpanded, setIsChatExpanded] = useState(true);
    const [currentSection, setCurrentSection] = useState(0);
    const [isMobile, setIsMobile] = useState(false);
    const scrollRef = useRef(null);

    useEffect(() => {
        const checkMobile = () => {
            const isMobileView = window.innerWidth < 1024;
            setIsMobile(isMobileView);
            if (isMobileView) {
                setIsChatExpanded(false);
            }
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);
        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    const handleScroll = () => {
        if (!scrollRef.current || isMobile) return;
        
        const container = scrollRef.current;
        const scrollPosition = container.scrollTop;
        const sectionHeight = container.clientHeight;
        const sectionIndex = Math.round(scrollPosition / sectionHeight);
        
        if (sectionIndex !== currentSection) {
            setCurrentSection(sectionIndex);
        }
    };

    return (
        <>
            <ScrollStyles />
            <div className="flex h-full overflow-hidden section-container">
                <div
                    ref={scrollRef}
                    onScroll={handleScroll}
                    className={`
                        relative flex-1
                        transition-all duration-300 
                        ${isMobile || !isChatExpanded ? 'w-full' : 'w-4/5'}
                        overflow-y-auto hide-scrollbar
                        ${!isMobile && 'snap-mandatory snap-y'}
                        ${isMobile && 'pb-16'}
                    `}
                >
                    <div className={`
                        ${!isMobile && 'h-full'}
                        ${isMobile && 'w-full'}
                    `}>
                        {sections.map(({ id, Component }) => (
                            <section 
                                key={id} 
                                id={id} 
                                className={`
                                    relative w-full
                                    ${!isMobile ? 'h-full snap-start' : 'min-h-screen'}
                                `}
                            >
                                <Component />
                            </section>
                        ))}
                    </div>
                </div>

                {!isMobile && (
                    <div className={`transition-all duration-300 ${isChatExpanded ? 'w-1/5' : 'w-12'}`}>
                        <ChatWindow
                            isExpanded={isChatExpanded}
                            onToggle={() => setIsChatExpanded(!isChatExpanded)}
                            isMobile={false}
                            sessionId={sessionId}
                        />
                    </div>
                )}

                {isMobile && (
                    <div className="fixed bottom-0 left-0 right-0 z-50">
                        <ChatWindow
                            isExpanded={isChatExpanded}
                            onToggle={() => setIsChatExpanded(!isChatExpanded)}
                            isMobile={true}
                            sessionId={sessionId}
                        />
                    </div>
                )}

                <PageIndicator
                    currentSection={currentSection}
                    setCurrentSection={setCurrentSection}
                />
            </div>
        </>
    );
};

export default Home;