import React, { useState } from 'react';
import {
    Briefcase,
    MessageCircle,
    Lightbulb,
    Code,
    BookOpen,
    HeartHandshake,
    Glasses,
    GraduationCap,
    Microscope,
    PenTool,
    Bot,
    AlertCircle,
    ArrowLeft,
    ArrowRight,
    Check
} from 'lucide-react';

// Options arrays remain the same
const toneOptions = [
    { id: 'professional', icon: Briefcase, label: 'Professional', description: 'Formal and business-oriented' },
    { id: 'friendly', icon: HeartHandshake, label: 'Friendly', description: 'Warm and approachable' },
    { id: 'technical', icon: Code, label: 'Technical', description: 'Detailed and precise' },
    { id: 'casual', icon: MessageCircle, label: 'Casual', description: 'Relaxed and conversational' },
    { id: 'academic', icon: BookOpen, label: 'Academic', description: 'Scholarly and analytical' }
];

const fieldOptions = [
    { id: 'technology', icon: Code, label: 'Technology', description: 'Software, IT, and digital solutions' },
    { id: 'business', icon: Briefcase, label: 'Business', description: 'Management, finance, and operations' },
    { id: 'education', icon: GraduationCap, label: 'Education', description: 'Teaching and learning' },
    { id: 'science', icon: Microscope, label: 'Science', description: 'Research and analysis' },
    { id: 'creative', icon: PenTool, label: 'Creative', description: 'Design and content creation' }
];

const useCaseOptions = [
    { id: 'assistant', icon: Bot, label: 'Virtual Assistant', description: 'General support and tasks' },
    { id: 'consultant', icon: Lightbulb, label: 'Consultant', description: 'Specialized advice and guidance' },
    { id: 'researcher', icon: Microscope, label: 'Researcher', description: 'Data analysis and insights' },
    { id: 'tutor', icon: Glasses, label: 'Tutor', description: 'Learning and education' }
];

const Initialize = ({ token, onSuccess }) => {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({
        name: '',
        tone: '',
        field: '',
        use_case: '',
        custom_instruction: ''
    });
    const [status, setStatus] = useState({
        loading: false,
        error: null,
        success: false
    });

    const handleSelect = (field, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: value
        }));
    };

    const handleSubmit = async () => {
        setStatus({ loading: true, error: null, success: false });

        try {
            const response = await fetch('/api/instance/set', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(formData)
            });

            const data = await response.json();
            if (response.ok) {
                setStatus({ loading: false, error: null, success: true });
                onSuccess?.();
            } else {
                throw new Error(data.message || 'Failed to create instance');
            }
        } catch (error) {
            setStatus({
                loading: false,
                error: error.message || 'Failed to create instance. Please try again.',
                success: false
            });
        }
    };

    const ChoiceCard = ({ option, selected, onSelect }) => (
        <div
            onClick={() => onSelect(option.id)}
            className={`relative flex flex-col items-center p-4 sm:p-6 rounded-xl cursor-pointer transition-all duration-300 transform hover:-translate-y-1 bg-white border-2 
                ${selected === option.id ? 'border-orange-500 shadow-lg' : 'border-gray-200 hover:border-orange-200 hover:shadow-md'}`}
        >
            <div className={`p-3 rounded-full mb-3 sm:mb-4 transition-colors duration-300 
                ${selected === option.id ? 'bg-orange-100 text-orange-600' : 'bg-gray-100 text-gray-600 group-hover:bg-orange-50 group-hover:text-orange-500'}`}>
                <option.icon className="w-5 h-5 sm:w-6 sm:h-6" />
            </div>
            <h3 className={`text-base sm:text-lg font-semibold mb-1 sm:mb-2 transition-colors duration-300 text-center
                ${selected === option.id ? 'text-orange-600' : 'text-gray-800'}`}>
                {option.label}
            </h3>
            <p className="text-xs sm:text-sm text-gray-500 text-center">{option.description}</p>
            {selected === option.id && (
                <div className="absolute top-2 right-2 sm:top-3 sm:right-3 text-orange-500">
                    <Check className="w-4 h-4 sm:w-5 sm:h-5" />
                </div>
            )}
        </div>
    );

    const NavigationButtons = ({ onBack, onNext, nextDisabled }) => (
        <div className="flex justify-between mt-6 sm:mt-8">
            {onBack && (
                <button
                    onClick={onBack}
                    className="flex items-center px-4 sm:px-6 py-2 sm:py-3 text-gray-600 hover:text-gray-800 transition-colors duration-300"
                >
                    <ArrowLeft className="w-4 h-4 mr-2" />
                    <span className="hidden sm:inline">Back</span>
                </button>
            )}
            {onNext && (
                <button
                    onClick={onNext}
                    disabled={nextDisabled}
                    className={`flex items-center px-4 sm:px-6 py-2 sm:py-3 rounded-xl font-medium transition-all duration-300
                        ${nextDisabled
                            ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                            : 'bg-orange-500 text-white hover:bg-orange-600 shadow-md hover:shadow-lg'
                        }`}
                >
                    {status.loading ? (
                        <div className="flex items-center">
                            <div className="animate-spin rounded-full h-4 w-4 border-2 border-white border-t-transparent mr-2" />
                            <span className="hidden sm:inline">Creating...</span>
                            <span className="sm:hidden">...</span>
                        </div>
                    ) : (
                        <>
                            <span className="hidden sm:inline">Next</span>
                            <span className="sm:hidden">Next</span>
                            <ArrowRight className="w-4 h-4 ml-2" />
                        </>
                    )}
                </button>
            )}
        </div>
    );

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="space-y-4 sm:space-y-6">
                        <h2 className="text-xl sm:text-2xl font-bold text-gray-800">Name your AI Assistant</h2>
                        <div className="relative">
                            <Bot className="absolute left-3 sm:left-4 top-1/2 -translate-y-1/2 text-gray-400 w-5 h-5" />
                            <input
                                type="text"
                                value={formData.name}
                                onChange={(e) => handleSelect('name', e.target.value)}
                                placeholder="Enter a name for your AI assistant"
                                className="w-full pl-10 sm:pl-12 pr-4 py-3 sm:py-4 bg-gray-50 border-2 border-gray-200 rounded-xl focus:border-orange-500 focus:ring-0 focus:bg-white transition-all duration-300 text-sm sm:text-base"
                            />
                        </div>
                        <NavigationButtons
                            onNext={() => setStep(2)}
                            nextDisabled={!formData.name}
                        />
                    </div>
                );

            case 2:
                return (
                    <div className="space-y-4 sm:space-y-6">
                        <h2 className="text-xl sm:text-2xl font-bold text-gray-800">Choose Communication Style</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4">
                            {toneOptions.map(option => (
                                <ChoiceCard
                                    key={option.id}
                                    option={option}
                                    selected={formData.tone}
                                    onSelect={(value) => handleSelect('tone', value)}
                                />
                            ))}
                        </div>
                        <NavigationButtons
                            onBack={() => setStep(1)}
                            onNext={() => setStep(3)}
                            nextDisabled={!formData.tone}
                        />
                    </div>
                );

            case 3:
                return (
                    <div className="space-y-4 sm:space-y-6">
                        <h2 className="text-xl sm:text-2xl font-bold text-gray-800">Select Field of Expertise</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-3 sm:gap-4">
                            {fieldOptions.map(option => (
                                <ChoiceCard
                                    key={option.id}
                                    option={option}
                                    selected={formData.field}
                                    onSelect={(value) => handleSelect('field', value)}
                                />
                            ))}
                        </div>
                        <NavigationButtons
                            onBack={() => setStep(2)}
                            onNext={() => setStep(4)}
                            nextDisabled={!formData.field}
                        />
                    </div>
                );

            case 4:
                return (
                    <div className="space-y-4 sm:space-y-6">
                        <h2 className="text-xl sm:text-2xl font-bold text-gray-800">Choose Primary Use Case</h2>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
                            {useCaseOptions.map(option => (
                                <ChoiceCard
                                    key={option.id}
                                    option={option}
                                    selected={formData.use_case}
                                    onSelect={(value) => handleSelect('use_case', value)}
                                />
                            ))}
                        </div>
                        <div className="mt-6 sm:mt-8">
                            <label className="block text-base sm:text-lg font-semibold text-gray-700 mb-2">
                                Additional Instructions (Optional)
                            </label>
                            <textarea
                                value={formData.custom_instruction}
                                onChange={(e) => handleSelect('custom_instruction', e.target.value)}
                                placeholder="Add any specific requirements or instructions..."
                                className="w-full p-3 sm:p-4 bg-gray-50 border-2 border-gray-200 rounded-xl focus:border-orange-500 focus:ring-0 focus:bg-white transition-all duration-300 min-h-[120px] resize-none text-sm sm:text-base"
                            />
                        </div>
                        <NavigationButtons
                            onBack={() => setStep(3)}
                            onNext={handleSubmit}
                            nextDisabled={!formData.use_case}
                        />
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="bg-white rounded-xl shadow-lg">
            <div className="p-4 sm:p-6">
                <div className="flex flex-col sm:flex-row sm:items-center mb-6 sm:mb-8">
                    <div className="p-3 bg-orange-50 rounded-xl mb-4 sm:mb-0 sm:mr-4 w-fit">
                        <Bot className="w-6 h-6 sm:w-8 sm:h-8 text-orange-500" />
                    </div>
                    <div>
                        <h1 className="text-xl sm:text-2xl font-bold text-gray-900">Initialize AI Assistant</h1>
                        <p className="text-sm sm:text-base text-gray-600 mt-1">Configure your AI assistant's personality and capabilities</p>
                    </div>
                </div>

                {status.error && (
                    <div className="mb-6 p-3 sm:p-4 bg-red-50 border border-red-200 rounded-xl flex items-center text-red-700 text-sm sm:text-base">
                        <AlertCircle className="w-5 h-5 mr-2 flex-shrink-0" />
                        {status.error}
                    </div>
                )}

                {status.success ? (
                    <div className="text-center py-8 sm:py-12">
                        <div className="w-12 h-12 sm:w-16 sm:h-16 bg-green-50 rounded-full flex items-center justify-center mx-auto mb-4">
                            <Check className="w-6 h-6 sm:w-8 sm:h-8 text-green-500" />
                        </div>
                        <h2 className="text-xl sm:text-2xl font-bold text-gray-900 mb-2">AI Assistant Created!</h2>
                        <p className="text-sm sm:text-base text-gray-600 mb-6 sm:mb-8">Your AI assistant is ready to help.</p>
                        <button
                            onClick={() => {
                                setStatus({ loading: false, error: null, success: false });
                                setStep(1);
                                setFormData({
                                    name: '',
                                    tone: '',
                                    field: '',
                                    use_case: '',
                                    custom_instruction: ''
                                });
                            }}
                            className="px-4 sm:px-6 py-2 sm:py-3 bg-orange-500 text-white rounded-xl hover:bg-orange-600 transition-colors duration-300 shadow-md hover:shadow-lg text-sm sm:text-base"
                        >
                            Create Another Assistant
                        </button>
                    </div>
                ) : (
                    <div>
                        <div className="mb-6 sm:mb-8 flex justify-between">
                            {[1, 2, 3, 4].map((stepNumber) => (
                                <div key={stepNumber} className="flex-1 px-1 sm:px-2">
                                    <div
                                        className={`h-1 sm:h-2 rounded-full transition-all duration-500 
                                            ${stepNumber <= step ? 'bg-orange-500' : 'bg-gray-200'}`}
                                    />
                                    <span className="text-xs text-gray-500 mt-1 hidden sm:block">
                                        Step {stepNumber}
                                    </span>
                                </div>
                            ))}
                        </div>
                        {renderStep()}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Initialize;