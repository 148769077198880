import React, { useState } from 'react';
import { 
    BiBrain,
    BiSearchAlt2, 
    BiCode,
    BiLineChart,
    BiLinkExternal,
    BiWindowAlt,
    BiCalendar,
    BiEnvelope,
    BiLogoGithub,
    BiNetworkChart,
    BiMessageRounded,
    BiRightArrowAlt,
    BiSupport,
    BiUserCircle,
    BiBookOpen,
    BiLock,
    BiPlus,
    BiMinus,
    BiDollar,
    BiTargetLock,
    BiGroup,
    BiFile,
    BiData,
    BiBot,
    BiCloud
} from 'react-icons/bi';

const FeatureCard = ({ feature }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div 
            className={`group rounded-xl border border-slate-200 bg-white/50 p-8 backdrop-blur-sm transition-all hover:border-orange-200 hover:bg-white/80 cursor-pointer ${isExpanded ? 'hover:shadow-xl shadow-lg' : 'hover:shadow-lg'}`}
            onClick={() => setIsExpanded(!isExpanded)}
        >
            <div className="flex justify-between items-start">
                <div className="rounded-lg bg-orange-100 p-3 w-fit">
                    <feature.icon className="h-8 w-8 text-orange-500 transition-transform group-hover:scale-110" />
                </div>
                {isExpanded ? 
                    <BiMinus className="h-6 w-6 text-slate-400" /> :
                    <BiPlus className="h-6 w-6 text-slate-400" />
                }
            </div>
            <h3 className="mt-6 text-2xl font-semibold text-slate-700">
                {feature.title}
            </h3>
            <p className="mt-4 text-slate-600 leading-relaxed">
                {feature.shortDescription}
            </p>
            <div className={`transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-96 opacity-100 mt-4' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                <p className="text-slate-600 leading-relaxed text-lg border-t border-slate-200 pt-4 mt-4">
                    {feature.longDescription}
                </p>
                {feature.details && (
                    <ul className="mt-4 space-y-2">
                        {feature.details.map((detail, index) => (
                            <li key={index} className="flex items-center gap-2 text-slate-600">
                                <div className="h-1.5 w-1.5 rounded-full bg-orange-500" />
                                {detail}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

const UseCaseCard = ({ useCase }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div 
            className={`rounded-xl border border-slate-200 bg-white/50 p-8 backdrop-blur-sm transition-all cursor-pointer ${isExpanded ? 'hover:shadow-xl shadow-lg' : 'hover:shadow-lg'}`}
            onClick={() => setIsExpanded(!isExpanded)}
        >
            <div className="flex justify-between items-start">
                <div className="rounded-lg bg-indigo-100 p-3 w-fit">
                    <useCase.icon className="h-8 w-8 text-indigo-500" />
                </div>
                {isExpanded ? 
                    <BiMinus className="h-6 w-6 text-slate-400" /> :
                    <BiPlus className="h-6 w-6 text-slate-400" />
                }
            </div>
            <h3 className="mt-6 text-2xl font-semibold text-slate-700">{useCase.title}</h3>
            <p className="mt-4 text-slate-600 leading-relaxed">
                {useCase.shortDescription}
            </p>
            <div className={`transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-96 opacity-100 mt-4' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                <p className="text-slate-600 leading-relaxed text-lg border-t border-slate-200 pt-4 mt-4">
                    {useCase.longDescription}
                </p>
                <ul className="mt-6 space-y-4">
                    {useCase.features.map((feature, fIndex) => (
                        <li key={fIndex} className="flex items-center gap-3 text-slate-600 text-lg">
                            <div className="h-1.5 w-1.5 rounded-full bg-indigo-500" />
                            {feature}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const IntegrationCard = ({ integration }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div 
            className={`rounded-xl border border-slate-200 bg-white/50 p-6 text-center backdrop-blur-sm transition-all cursor-pointer ${isExpanded ? 'hover:shadow-xl shadow-lg' : 'hover:shadow-lg'}`}
            onClick={() => setIsExpanded(!isExpanded)}
        >
            <div className="flex justify-center relative">
                <div className="rounded-lg bg-slate-100 p-3 w-fit">
                    <integration.icon className="h-8 w-8 text-slate-600" />
                </div>
                <div className="absolute right-0 top-0">
                    {isExpanded ? 
                        <BiMinus className="h-6 w-6 text-slate-400" /> :
                        <BiPlus className="h-6 w-6 text-slate-400" />
                    }
                </div>
            </div>
            <h3 className="mt-4 text-xl font-semibold text-slate-700">{integration.name}</h3>
            <ul className={`transition-all duration-300 ease-in-out ${isExpanded ? 'max-h-96 opacity-100 mt-4' : 'max-h-0 opacity-0 overflow-hidden'}`}>
                {integration.features.map((feature, fIndex) => (
                    <li key={fIndex} className="text-slate-600 py-2 text-base">{feature}</li>
                ))}
            </ul>
        </div>
    );
};

const BTStudio = () => {
    const features = [
        {
            icon: BiBrain,
            title: "Custom AI Agents",
            shortDescription: "Create AI assistants tailored to your specific needs",
            longDescription: "Build sophisticated AI agents that understand your business context, workflows, and requirements. Our platform enables you to create highly specialized assistants that can handle complex tasks and interactions.",
            details: [
                "Customizable personality and tone",
                "Domain-specific knowledge integration",
                "Adaptive learning capabilities",
                "Multi-language support"
            ]
        },
        {
            icon: BiSearchAlt2,
            title: "Smart Knowledge Base",
            shortDescription: "Powerful semantic search across your data",
            longDescription: "Connect and query your entire knowledge base with advanced semantic search capabilities. Our system understands context and relationships between different pieces of information to provide accurate and relevant responses.",
            details: [
                "Real-time document indexing",
                "Context-aware search",
                "Multiple data source integration",
                "Automated knowledge graph creation"
            ]
        },
        {
            icon: BiCode,
            title: "Function Calling",
            shortDescription: "Automate tasks through secure API integrations",
            longDescription: "Enable your AI assistants to perform actions and automate workflows through secure function calling and API integrations. Connect with your existing systems and tools seamlessly.",
            details: [
                "Secure API authentication",
                "Custom function definition",
                "Webhook support",
                "Error handling and monitoring"
            ]
        },
        {
            icon: BiCloud,
            title: "Multi Integrations",
            shortDescription: "Connect with your essential workplace tools",
            longDescription: "Enhance your AI assistant by connecting it to your everyday work applications. Seamlessly integrate with email, chat, calendars, and productivity tools to enable AI assistance across all your workflows.",
            details: [
                "Email and calendar integration",
                "Chat platform connections",
                "Project management tools",
                "Document and storage apps"
            ]
        },
        {
            icon: BiLineChart,
            title: "Real-time Analytics",
            shortDescription: "Monitor performance and usage patterns",
            longDescription: "Get detailed insights into your AI assistants' performance, usage patterns, and costs. Make data-driven decisions with comprehensive analytics and reporting tools.",
            details: [
                "Usage tracking and analysis",
                "Performance metrics",
                "Cost optimization insights",
                "Custom report generation"
            ]
        },
        {
            icon: BiLock,
            title: "Enterprise Security",
            shortDescription: "Bank-grade security and compliance",
            longDescription: "Ensure your data and operations are protected with enterprise-grade security features. Our platform maintains compliance with industry standards while providing flexible access controls.",
            details: [
                "End-to-end encryption",
                "Role-based access control",
                "Audit logging",
                "Compliance certifications"
            ]
        }
    ];

    const useCases = [
        {
            icon: BiSupport,
            title: "Customer Support",
            shortDescription: "24/7 AI-powered customer support automation",
            longDescription: "Build AI agents that handle customer inquiries around the clock, with seamless access to your product documentation and support history. Improve response times and customer satisfaction while reducing support costs.",
            features: [
                "Semantic search across support docs",
                "Ticket creation & management",
                "Automated response suggestions",
                "Customer satisfaction tracking"
            ]
        },
        {
            icon: BiUserCircle,
            title: "Personal Assistant",
            shortDescription: "AI assistant for calendar and task management",
            longDescription: "Create an AI assistant that manages your calendar, emails, and daily tasks with precise context. Let AI handle the routine while you focus on what matters most.",
            features: [
                "Calendar management",
                "Email drafting & scheduling",
                "Task prioritization",
                "Meeting preparation"
            ]
        },
        {
            icon: BiBookOpen,
            title: "Knowledge Management",
            shortDescription: "Smart organization of company knowledge",
            longDescription: "Deploy AI agents that organize and retrieve information from your company's knowledge base. Transform scattered information into accessible, actionable insights.",
            features: [
                "Document analysis",
                "Smart search & retrieval",
                "Automated summarization",
                "Content organization"
            ]
        },
        {
            icon: BiDollar,
            title: "Sales Assistant",
            shortDescription: "AI-powered sales process optimization",
            longDescription: "Empower your sales team with AI-driven insights and automation. Streamline your sales process and close more deals with intelligent assistance.",
            features: [
                "Lead qualification",
                "Sales pipeline management",
                "Proposal generation",
                "Follow-up automation"
            ]
        },
        {
            icon: BiTargetLock,
            title: "Marketing Automation",
            shortDescription: "AI-enhanced marketing campaign management",
            longDescription: "Create personalized marketing campaigns and content with AI assistance. Deliver the right message to the right audience at the right time.",
            features: [
                "Content generation",
                "Campaign optimization",
                "Audience analysis",
                "Performance tracking"
            ]
        },
        {
            icon: BiGroup,
            title: "HR & Recruitment",
            shortDescription: "Streamlined HR processes with AI",
            longDescription: "Streamline HR processes and enhance recruitment workflows. Find and nurture the best talent while automating routine tasks.",
            features: [
                "Resume screening",
                "Candidate matching",
                "Interview scheduling",
                "Onboarding assistance"
            ]
        },
        {
            icon: BiFile,
            title: "Document Processing",
            shortDescription: "Automated document analysis and extraction",
            longDescription: "Automate document handling and extract valuable insights. Transform unstructured documents into structured, actionable data.",
            features: [
                "Data extraction",
                "Document classification",
                "Compliance checking",
                "Information validation"
            ]
        },
        {
            icon: BiData,
            title: "Data Analysis",
            shortDescription: "AI-powered data insights generation",
            longDescription: "Transform raw data into actionable insights with AI-powered analysis. Uncover patterns and opportunities hidden in your data.",
            features: [
                "Data visualization",
                "Trend analysis",
                "Anomaly detection",
                "Report generation"
            ]
        },
        {
            icon: BiBot,
            title: "Process Automation",
            shortDescription: "Smart workflow automation solutions",
            longDescription: "Automate repetitive tasks and streamline business processes. Increase efficiency and reduce errors with intelligent automation.",
            features: [
                "Workflow automation",
                "System integration",
                "Error handling",
                "Performance monitoring"
            ]
        }
    ];

    const integrations = [
        {
            icon: BiLogoGithub,
            name: "GitHub",
            features: ["Code repository access", "Issue management", "PR reviews"]
        },
        {
            icon: BiCalendar,
            name: "Google Calendar",
            features: ["Event scheduling", "Meeting management", "Availability checking"]
        },
        {
            icon: BiEnvelope,
            name: "Email",
            features: ["Draft composition", "Smart filters", "Follow-up automation"]
        },
        {
            icon: BiNetworkChart,
            name: "LinkedIn",
            features: ["Profile analysis", "Connection management", "Content scheduling"]
        },
        {
            icon: BiMessageRounded,
            name: "Slack",
            features: ["Channel monitoring", "Response automation", "Task tracking"]
        }
    ];
    return (
        <div className="min-h-screen bg-gradient-to-br from-orange-50 via-indigo-50 to-orange-50">
            {/* Hero Section */}
            <section className="px-4 py-24">
                <div className="mx-auto max-w-6xl">
                    <div className="text-center">
                        <div className="mb-6 inline-flex items-center gap-2 rounded-full bg-orange-100 px-4 py-2">
                            <div className="h-2 w-2 rounded-full bg-orange-500 animate-pulse" />
                            <span className="text-orange-700 font-medium">Public Beta Now Available</span>
                        </div>
                        <h1 className="bg-gradient-to-r from-orange-600 to-indigo-600 bg-clip-text text-5xl font-bold tracking-tight text-transparent sm:text-7xl">
                            Botenders Studio
                        </h1>
                        <p className="mx-auto mt-8 max-w-2xl text-xl leading-relaxed text-slate-600">
                            Create custom AI assistants that seamlessly integrate with your tools and workflows. From personal productivity to enterprise solutions.
                        </p>
                        
                        <div className="mt-10 flex flex-col items-center justify-center gap-4 sm:flex-row">
                            <a
                                href="/studio"
                                className="group relative flex items-center gap-2 rounded-xl bg-gradient-to-r from-orange-500 to-indigo-500 px-8 py-4 font-semibold text-white transition-all hover:from-orange-600 hover:to-indigo-600 hover:shadow-lg"
                            >
                                Open Studio
                                <BiWindowAlt className="h-5 w-5" />
                            </a>
                            <a
                                href="/docs"
                                className="flex items-center gap-2 rounded-xl border border-slate-200 bg-white/50 px-6 py-4 font-semibold text-slate-700 backdrop-blur-sm transition-all hover:border-orange-200 hover:bg-white/80 hover:shadow-lg"
                            >
                                View Documentation
                                <BiLinkExternal className="h-5 w-5" />
                            </a>
                        </div>
                    </div>
                </div>
            </section>
    
            {/* Features Grid */}
            <section className="px-4 py-20 bg-gradient-to-br from-orange-50/50 to-orange-100/30">
                <div className="mx-auto max-w-7xl">
                    <h2 className="text-4xl font-bold text-center text-slate-700 mb-4">Core Features</h2>
                    <p className="text-xl text-center text-slate-600 mb-16">Everything you need to build powerful AI assistants</p>
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {features.map((feature, index) => (
                            <FeatureCard key={index} feature={feature} />
                        ))}
                    </div>
                </div>
            </section>
    
            {/* Use Cases Section */}
            <section className="px-4 py-20 bg-gradient-to-br from-indigo-50/30 to-slate-50">
                <div className="mx-auto max-w-7xl">
                    <h2 className="text-4xl font-bold text-center text-slate-700 mb-4">Popular Use Cases</h2>
                    <p className="text-xl text-center text-slate-600 mb-16">Discover how others are using Botenders Studio</p>
                    <div className="grid gap-8 md:grid-cols-3">
                        {useCases.map((useCase, index) => (
                            <UseCaseCard key={index} useCase={useCase} />
                        ))}
                    </div>
                </div>
            </section>
    
            {/* Integrations Section */}
            <section className="px-4 py-20 bg-gradient-to-br from-slate-50 to-slate-100/50">
                <div className="mx-auto max-w-7xl">
                    <h2 className="text-4xl font-bold text-center text-slate-700 mb-4">Key Integrations</h2>
                    <p className="text-xl text-center text-slate-600 mb-16">Connect with your favorite tools and platforms</p>
                    <div className="grid gap-8 md:grid-cols-3 lg:grid-cols-5">
                        {integrations.map((integration, index) => (
                            <IntegrationCard key={index} integration={integration} />
                        ))}
                    </div>
                </div>
            </section>
    
            {/* Final CTA */}
            <section className="px-4 py-20">
                <div className="mx-auto max-w-4xl rounded-2xl border border-slate-200 bg-white/50 p-12 text-center backdrop-blur-sm hover:shadow-lg transition-all">
                    <div className="inline-flex items-center gap-2 rounded-full bg-orange-100 px-4 py-2">
                        <div className="h-2 w-2 rounded-full bg-orange-500 animate-pulse" />
                        <span className="text-orange-700 font-medium">Limited Beta Pricing Available</span>
                    </div>
                    <h2 className="mt-6 text-4xl font-bold text-slate-700">
                        Start Building Your Custom AI Assistant
                    </h2>
                    <p className="mt-4 text-xl text-slate-600">
                        Join the beta program and transform how you work with AI
                    </p>
                    <div className="mt-10 flex flex-col items-center justify-center gap-4 sm:flex-row">
                        <a
                            href="/studio"
                            className="group flex items-center gap-2 rounded-xl bg-gradient-to-r from-orange-500 to-indigo-500 px-8 py-4 font-semibold text-white transition-all hover:from-orange-600 hover:to-indigo-600 hover:shadow-lg"
                        >
                            Start Building
                            <BiRightArrowAlt className="h-5 w-5 transition-transform group-hover:translate-x-1" />
                        </a>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BTStudio;