import React, { useState, useEffect } from 'react';
import {
    Mail,
    // Calendar,
    // FileText,
    AlertCircle,
    Check,
    // GitBranch,
    // MessageSquare,
    // Video,
    // Database,
    // Cloud,
    ChevronDown,
    ChevronUp,
    Info
} from 'lucide-react';

// // Provider to service mapping
// const PROVIDER_SERVICE_MAP = {
//     google: ['gmail', 'google_calendar', 'google_drive', 'google_meet'],
//     microsoft: ['outlook', 'outlook_calendar', 'onedrive', 'teams'],
//     github: ['github'],
//     gitlab: ['gitlab'],
//     bitbucket: ['bitbucket'],
//     slack: ['slack'],
//     discord: ['discord'],
//     zoom: ['zoom'],
//     aws: ['aws_s3'],
//     dropbox: ['dropbox'],
//     mongodb_atlas: ['mongodb'],
//     supabase: ['supabase']
// };

const INTEGRATION_CATEGORIES = {
    email: {
        title: 'Email',
        icon: <Mail className="w-5 h-5" />,
        description: 'Connect your email accounts to enable email automation',
        services: [
            {
                id: 'gmail',
                name: 'Gmail',
                provider: 'google',
                logo: '/logos/gmail.svg',
                requiredScopes: ['https://www.googleapis.com/auth/gmail.send', 'https://www.googleapis.com/auth/gmail.readonly'],
            },
            // {
            //     id: 'outlook',
            //     name: 'Outlook',
            //     provider: 'microsoft',
            //     logo: '/logos/outlook.svg',
            //     requiredScopes: ['mail.send', 'mail.read'],
            // }
        ],
    },
    calendar: {
        title: 'Calendar',
        icon: <Mail className="w-5 h-5" />,
        description: 'Connect your calendar to enable calendar automation',
        services: [
            {
                id: 'google_calendar',
                name: 'Google Calendar',
                provider: 'google',
                logo: '/logos/google_calendar.svg',
                requiredScopes: ['https://www.googleapis.com/auth/calendar', 'https://www.googleapis.com/auth/calendar.events'],
            },
            // {
            //     id: 'outlook_calendar',
            //     name: 'Outlook Calendar',
            //     provider: 'microsoft',
            //     logo: '/logos/outlook_calendar.svg',
            //     requiredScopes: ['calendar.read', 'calendar.write'],
            // }
        ],
    },
    workspace: {
        title: 'Workspace',
        icon: <Mail className="w-5 h-5" />,
        description: 'Connect your workspace to enable workspace automation',
        services: [
            {
                id: 'slack',
                name: 'Slack',
                provider: 'slack',
                logo: '/logos/slack.svg',
                requiredScopes: [
                    "app_mentions:read",
                    "chat:write",
                    "im:history",
                    "im:read",
                    "im:write",
                    "incoming-webhook"],
            },
            // {
            //     id: 'teams',
            //     name: 'Microsoft Teams',
            //     provider: 'microsoft',
            //     logo: '/logos/teams.svg',
            //     requiredScopes: ['team.read', 'team.write'],
            // }
        ],
    },

};

// Helper function to check if a service is connected
const isServiceConnected = (service, connections) => {
    const providerConnection = connections[service.provider];
    if (!providerConnection) return false;
    
    return service.requiredScopes.every(scope => 
        providerConnection.scopes.includes(scope)
    );
};

// Helper function to get missing scopes for a service
const getMissingScopes = (service, connections) => {
    const providerConnection = connections[service.provider];
    if (!providerConnection) return service.requiredScopes;
    
    return service.requiredScopes.filter(scope => 
        !providerConnection.scopes.includes(scope)
    );
};

const ConnectedServicesSummary = ({ connections }) => {
    const connectedServices = Object.values(INTEGRATION_CATEGORIES)
        .flatMap(category => 
            category.services.filter(service => 
                isServiceConnected(service, connections)
            )
        );

    return (
        <div className="bg-white rounded-lg border border-gray-200 p-6 mb-6">
            <h2 className="text-lg font-semibold text-gray-900 mb-4">Connected Services</h2>

            {connectedServices.length === 0 ? (
                <div className="text-sm text-gray-500 p-4 bg-gray-50 rounded-lg">
                    No active integrations
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    {connectedServices.map(service => {
                        // const connection = connections[service.provider];
                        return (
                            <div
                                key={service.id}
                                className="bg-gray-50 rounded-lg p-4 border border-gray-100"
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img src={service.logo} alt={service.name} className="w-5 h-5" />
                                        <span className="ml-2 text-sm font-medium">{service.name}</span>
                                    </div>
                                    <div className="flex items-center">
                                        <span className="w-2 h-2 bg-green-500 rounded-full"></span>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <span className="text-xs text-gray-500">
                                        Connected via {service.provider}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const IntegrationCard = ({ category, connections, onConnect, onDisconnect }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
            <div className="p-6">
                <button
                    onClick={() => setIsExpanded(!isExpanded)}
                    className="w-full flex items-center justify-between group"
                >
                    <div className="flex items-center">
                        <div className="p-2 bg-green-50 rounded-lg">
                            {category.icon}
                        </div>
                        <div className="ml-4 text-left">
                            <h3 className="text-lg font-medium text-gray-900">{category.title}</h3>
                            <p className="text-sm text-gray-500">{category.description}</p>
                        </div>
                    </div>
                    <div className="ml-4 transition-transform duration-200">
                        {isExpanded ? (
                            <ChevronUp className="w-5 h-5 text-gray-400 group-hover:text-gray-600" />
                        ) : (
                            <ChevronDown className="w-5 h-5 text-gray-400 group-hover:text-gray-600" />
                        )}
                    </div>
                </button>

                <div
                    className={`mt-6 space-y-4 transition-all duration-200 ease-in-out overflow-hidden ${
                        isExpanded ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0'
                    }`}
                >
                    {category.services.map((service) => {
                        const connection = connections[service.provider];
                        const missingScopes = getMissingScopes(service, connections);
                        const isConnected = missingScopes.length === 0;
                        const partiallyConnected = connection && missingScopes.length > 0;

                        return (
                            <div
                                key={service.id}
                                className="flex flex-col p-4 bg-gray-50 rounded-lg"
                            >
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <img src={service.logo} alt={service.name} className="w-6 h-6" />
                                        <span className="ml-3 text-sm font-medium text-gray-900">{service.name}</span>
                                    </div>

                                    {isConnected ? (
                                        <div className="flex items-center">
                                            <span className="flex items-center text-sm text-green-600 mr-4">
                                                <Check className="w-4 h-4 mr-1" />
                                                Connected
                                            </span>
                                            <button
                                                onClick={() => onDisconnect(service.provider)}
                                                className="text-sm text-red-600 hover:text-red-700"
                                            >
                                                Disconnect
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => onConnect(service.provider, service.requiredScopes)}
                                            className={`px-4 py-2 text-sm font-medium text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-offset-2 ${
                                                partiallyConnected
                                                    ? 'bg-orange-500 hover:bg-orange-600 focus:ring-orange-500'
                                                    : 'bg-green-600 hover:bg-green-700 focus:ring-green-500'
                                            }`}
                                        >
                                            {partiallyConnected ? 'Update Permissions' : 'Connect'}
                                        </button>
                                    )}
                                </div>

                                {partiallyConnected && (
                                    <div className="mt-3 flex items-start space-x-2 text-sm text-orange-700 bg-orange-50 p-3 rounded-md">
                                        <Info className="w-4 h-4 mt-0.5" />
                                        <div>
                                            <p className="font-medium">Additional permissions needed</p>
                                            <ul className="list-disc list-inside mt-1">
                                                {missingScopes.map(scope => (
                                                    <li key={scope} className="text-xs">{scope}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

const Integrations = ({ token }) => {
    const [connections, setConnections] = useState({});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [, setIsProcessing] = useState(false);

    useEffect(() => {
        const fetchConnections = async () => {
            try {
                const response = await fetch(`/api/account/integrations`, {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                });

                const { all } = await response.json();
                const connectionsMap = Array.isArray(all) ? all.reduce((acc, connection) => {
                    if (connection?.provider) {
                        acc[connection.provider] = connection;
                    }
                    return acc;
                }, {}) : {};

                setConnections(connectionsMap);
            } catch (err) {
                setError('Failed to fetch connections');
            } finally {
                setIsLoading(false);
            }
        };

        if (token) {
            fetchConnections();
        }
    }, [token]);

    const handleConnect = async (providerId, scopes) => {
        setIsProcessing(true);
        try {
            const response = await fetch(`/oauth/start?provider=${providerId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
                body: JSON.stringify({ scopes })
            });

            const { redirect_url } = await response.json();
            window.location.href = redirect_url;
        } catch (err) {
            setError('Failed to initiate connection');
        } finally {
            setIsProcessing(false);
        }
    };

    const handleDisconnect = async (providerId) => {
        setIsProcessing(true);
        try {
            await fetch(`/api/account/integrations/${providerId}`, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            setConnections(prev => {
                const next = { ...prev };
                delete next[providerId];
                return next;
            });
        } catch (err) {
            setError('Failed to disconnect provider');
        } finally {
            setIsProcessing(false);
        }
    };

    if (isLoading) {
        return <div className="p-6">Loading...</div>;
    }

    return (
        <div className="space-y-6">
            {error && (
                <div className="flex p-4 mb-4 bg-red-50 rounded-lg">
                    <AlertCircle className="w-5 h-5 text-red-400" />
                    <p className="ml-3 text-sm text-red-700">{error}</p>
                </div>
            )}

            <ConnectedServicesSummary connections={connections} />

            <div className="bg-white rounded-lg border border-gray-200 p-6">
                <h2 className="text-lg font-semibold text-gray-900 mb-6">Available Integrations</h2>
                <div className="space-y-6">
                    {Object.entries(INTEGRATION_CATEGORIES).map(([key, category]) => (
                        <IntegrationCard
                            key={key}
                            category={category}
                            connections={connections}
                            onConnect={handleConnect}
                            onDisconnect={handleDisconnect}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Integrations;