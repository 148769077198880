import React from 'react';
import { MessageSquare, Shield, Zap, Users, Lock } from 'lucide-react';

const FeatureCard = ({ icon: Icon, title, description }) => (
    <div className="flex items-center gap-6 rounded-xl bg-white/80 p-6 backdrop-blur-sm">
        <div className="inline-flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-xl bg-orange-100">
            <Icon className="h-6 w-6 text-orange-600" />
        </div>
        <div>
            <h3 className="font-mono text-lg font-bold text-gray-900">{title}</h3>
            <p className="mt-2 text-sm leading-relaxed text-gray-600">{description}</p>
        </div>
    </div>
);

const FeaturesSection = () => {
    const features = [
        {
            icon: MessageSquare,
            title: "Natural Intelligence",
            description: "Context-aware AI that learns and adapts to your unique workflow patterns in real-time."
        },
        {
            icon: Shield,
            title: "Enterprise Security",
            description: "SOC 2 Type II compliance with comprehensive end-to-end encryption and regular audits."
        },
        {
            icon: Zap,
            title: "Seamless Integration",
            description: "Connect with your existing tools and workflows in minutes, not days."
        },
        {
            icon: Users,
            title: "Built for Teams",
            description: "Real-time collaboration features with integrated version control and change tracking."
        },
        {
            icon: Lock,
            title: "Data Control",
            description: "Complete control over your data with flexible deployment options and privacy settings."
        }
    ];

    return (
        <div className="relative min-h-screen w-full overflow-hidden bg-gradient-to-br from-white via-orange-50 to-white pt-16">
            {/* Background Elements */}
            <div className="absolute inset-0 overflow-hidden">
                <div className="absolute -right-24 top-1/4 h-96 w-96 rounded-full bg-orange-100/30 blur-3xl" />
                <div className="absolute -left-24 top-3/4 h-96 w-96 rounded-full bg-blue-100/30 blur-3xl" />
            </div>

            {/* Content */}
            <div className="relative mx-auto flex h-[calc(100vh-4rem)] max-w-5xl flex-col justify-center px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col items-center gap-12">
                    {/* Header Section */}
                    <div className="text-center">
                        <h2 className="font-mono text-lg text-gray-800">
                            Platform Features
                        </h2>
                        <h1 className="mt-2 font-mono text-3xl font-bold tracking-tight text-gray-900 md:text-4xl">
                            Powerful
                            <span className="relative mx-2 whitespace-nowrap">
                                Intelligence
                                <span className="absolute -bottom-1 left-0 h-1 w-full bg-orange-200" />
                            </span>
                        </h1>
                    </div>

                    {/* Features */}
                    <div className="flex w-full max-w-3xl flex-col gap-4">
                        {features.map((feature, index) => (
                            <FeatureCard key={index} {...feature} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturesSection;