import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../auth/Firebase";
import {
    Check,
    ArrowRight,
    Rocket,
    Building,
    Cloud,
    Terminal,
    ChevronDown,
    ExternalLink,
    Laptop,
    Cpu
} from 'lucide-react';

const PricingCard = ({
    title,
    description,
    price,
    features,
    icon: Icon,
    buttonText,
    popular,
    beta,
    onClick,
    accent = "orange"  // changed default from "orange" to "orange"
}) => {
    const accentColors = {
        orange: {  // Changed from orange to orange
            border: "border-orange-500",
            bg: "bg-orange-50",
            text: "text-orange-500",
            button: "bg-orange-500 hover:bg-orange-600",
            badge: "bg-orange-500"
        },
        red: {
            border: "border-red-500",
            bg: "bg-red-50",
            text: "text-red-500",
            button: "bg-red-500 hover:bg-red-600",
            badge: "bg-red-500"
        },
        indigo: {
            border: "border-indigo-500",
            bg: "bg-indigo-50",
            text: "text-indigo-500",
            button: "bg-indigo-500 hover:bg-indigo-600",
            badge: "bg-indigo-500"
        }
    };

    const colors = accentColors[accent];

    return (
        <div className={`relative rounded-2xl border-2 bg-white p-8 shadow-lg transition-all duration-300 hover:shadow-xl ${popular ? colors.border + ' scale-105' : 'border-gray-100'
            }`}>
            {(popular || beta) && (
                <div className="absolute -top-4 left-1/2 -translate-x-1/2">
                    <div className={`rounded-full ${colors.badge} px-3 py-1 text-sm font-medium text-white`}>
                        {beta ? "Beta" : "Free Trial"}
                    </div>
                </div>
            )}

            <div className="flex items-center gap-4">
                <div className={`flex h-12 w-12 items-center justify-center rounded-xl ${popular ? colors.bg + ' ' + colors.text : 'bg-gray-50 text-gray-500'
                    }`}>
                    <Icon className="h-6 w-6" />
                </div>
                <div>
                    <h3 className="text-xl font-semibold text-gray-900">{title}</h3>
                    <p className="text-sm text-gray-500">{description}</p>
                </div>
            </div>

            {price && (
                <div className="mt-6">
                    <div className="flex items-baseline">
                        <span className="text-4xl font-bold text-gray-900">${price}</span>
                        <span className="ml-1 text-gray-500">/month</span>
                    </div>
                </div>
            )}

            <ul className="mt-8 space-y-4">
                {features.map((feature, index) => (
                    <li key={index} className="flex items-start gap-3">
                        <Check className="h-5 w-5 flex-shrink-0 text-green-500" />
                        <span className="text-gray-600">{feature}</span>
                    </li>
                ))}
            </ul>

            <button
                onClick={onClick}
                className={`mt-8 flex w-full items-center justify-center gap-2 rounded-xl px-4 py-3 text-sm font-medium transition-colors ${popular ? colors.button : 'bg-gray-900 hover:bg-gray-800'
                    } text-white`}
            >
                {buttonText}
                {buttonText === "Contact Sales" ? <ExternalLink className="h-4 w-4" /> : <ArrowRight className="h-4 w-4" />}
            </button>
        </div>
    );
};

const Pricing = () => {
    const [user] = useAuthState(auth);

    const handleCheckout = async (lookupId) => {
        if (!user) {
            window.location.href = '/signin';
            return;
        }

        try {
            const token = await user.getIdToken(true);
            const resp = await fetch('/api/billing/checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    price_id: lookupId,
                    success_url: window.location.href,
                    cancel_url: window.location.href,
                }),
            });

            if (resp.ok) {
                const { redirect_url } = await resp.json();
                window.location.href = redirect_url;
            }
        } catch (error) {
            console.error('Checkout error:', error);
        }
    };

    const plans = [
        {
            title: "Studio Essential",
            description: "For developers and small teams",
            price: "9.99",
            icon: Cloud,
            popular: true,
            accent: "orange",
            features: [
                "14-day free trial included",
                "10,000 API requests per month",
                "No-code AI agent builder",
                "Knowledge base with semantic search",
                "Email & calendar integration",
                "Basic analytics & monitoring",
                "Integrate into website and existing workflow"
            ],
            buttonText: "Start Free Trial",
            lookupId: 'AAA'
        },
        {
            title: "Studio Pro",
            description: "For growing businesses",
            price: "39.99",
            icon: Rocket,
            popular: false,
            accent: "orange",
            features: [
                "Everything included in the essential plan",
                "100,000 API requests per month",
                "Advanced AI model",
                "More advanced functionalities",
                "Multi-tool workspace integration",
                "Real-time analytics & reporting",
                "Custom templates library"
            ],
            buttonText: "Upgrade to Pro",
            lookupId: 'BBB'
        },
        {
            title: "Enterprise",
            description: "Custom AI solutions for organizations",
            icon: Building,
            accent: "indigo",
            features: [
                "Unlimited API requests",
                "100+ AI models",
                "Custom SLAs and support",
                "24/7 priority support",
                "Security compliance & audit logs",
                "Advanced team management",
                "Custom integration and applications",
                "Technical account manager"
            ],
            buttonText: "Contact Sales",
            lookupId: 'CCC'
        }
    ];

    return (
        <div className="min-h-screen bg-gradient-to-br from-slate-50 via-orange-50 to-slate-50">
            {/* Hero Section */}
            <section className="px-4 py-20">
                <div className="mx-auto max-w-4xl text-center">
                    <div className="mb-6 inline-flex items-center gap-2 rounded-full bg-orange-100 px-4 py-2">
                        <Cloud className="h-5 w-5 text-orange-600" />
                        <span className="text-orange-800 font-medium">Studio Pricing</span>
                    </div>
                    
                    <h1 className="bg-gradient-to-r from-orange-600 to-indigo-600 bg-clip-text text-4xl font-bold tracking-tight text-transparent sm:text-5xl mb-8 leading-relaxed pb-2">
                        Create Custom AI Agents in 5 Minutes
                    </h1>

                    <p className="mt-6 text-lg leading-relaxed text-slate-600">
                        No coding required - our intuitive platform lets you build and deploy custom AI agents in minutes, not months. Start with a template or build from scratch.
                    </p>

                    {/* Product Options Banner */}
                    <div className="mt-6 flex flex-col items-center justify-center gap-4 text-sm text-slate-600 sm:flex-row">
                        <div className="flex items-center gap-2">
                            <Laptop className="h-4 w-4 text-red-500" />
                            <a href="/product/deskmate" className="hover:text-red-600">
                                Looking for local deployment? Try Deskmate Alpha
                            </a>
                        </div>
                        <span className="hidden sm:inline text-slate-400">|</span>
                        <div className="flex items-center gap-2">
                            <Cpu className="h-4 w-4 text-orange-500" />
                            <a href="/product/os" className="hover:text-orange-600">
                                Interested in kernel-level AI? Learn about our OS
                            </a>
                        </div>
                    </div>
                </div>
            </section>

            {/* Pricing Cards */}
            <section className="px-4 py-16">
                <div className="mx-auto grid max-w-7xl gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {plans.map((plan, index) => (
                        <PricingCard
                            key={index}
                            {...plan}
                            onClick={() => handleCheckout(plan.lookupId)}
                        />
                    ))}
                </div>
                <div className="mt-8 flex items-center justify-center gap-2 text-slate-500">
                    <img src="/stripe-power.svg" alt="Stripe" className="h-6" />
                    <span className="text-sm">Secure payments powered by Stripe</span>
                </div>
            </section>

            {/* Studio Features Section */}
            <section className="bg-white/50 px-4 py-20 backdrop-blur-sm">
                <div className="mx-auto max-w-6xl">
                    <div className="text-center">
                        <h2 className="text-3xl font-bold text-slate-900">
                            Why choose Botenders Studio?
                        </h2>
                        <p className="mt-4 text-lg text-slate-600">
                            The fastest way to create and deploy custom AI agents
                        </p>
                    </div>

                    <div className="mt-16 grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {[
                            {
                                icon: Cloud,
                                title: "5-Minute Setup",
                                description: "Go from idea to working AI agent in minutes with our intuitive no-code builder"
                            },
                            {
                                icon: Terminal,
                                title: "Easy Integration",
                                description: "One-click deployment to your website, chat platforms, or business tools"
                            },
                            {
                                icon: Rocket,
                                title: "Powerful AI Features",
                                description: "Access to advanced capabilities like semantic search, multi-modal processing, and custom workflows"
                            }
                        ].map((feature, index) => (
                            <div key={index} className="rounded-xl border border-slate-200 bg-white/80 p-6 shadow-sm backdrop-blur-sm">
                                <feature.icon className="h-8 w-8 text-orange-500" />
                                <h3 className="mt-4 text-lg font-semibold text-slate-900">
                                    {feature.title}
                                </h3>
                                <p className="mt-2 text-slate-600">{feature.description}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Updated FAQ Section */}
            <section className="px-4 py-20">
                <div className="mx-auto max-w-3xl">
                    <h2 className="text-center text-3xl font-bold text-slate-900">
                        Frequently asked questions
                    </h2>
                    <div className="mt-12 space-y-6">
                        {[
                            {
                                question: "How quickly can I create an AI agent?",
                                answer: "You can create your first AI agent in just 5 minutes! Our intuitive no-code builder and ready-to-use templates make it incredibly simple. Just choose a template, customize it to your needs, and deploy - no technical expertise required."
                            },
                            {
                                question: "What capabilities does Studio provide?",
                                answer: "Studio provides comprehensive AI development tools including custom AI agents, knowledge base integration, function calling, multi-modal AI processing. It supports everything from basic chatbots to complex enterprise AI solutions."
                            },
                            {
                                question: "How does Studio compare to other products?",
                                answer: "Studio is our cloud-based solution offering maximum flexibility and scalability. Deskmate provides local deployment for privacy-focused users, while our upcoming OS will offer kernel-level AI integration for optimal performance."
                            },
                            {
                                question: "Can I integrate Studio with existing tools?",
                                answer: "Yes! Studio supports integration with common workplace tools including email, calendar, chat platforms, and project management systems. Enterprise plans offer custom integration support for specialized tools and workflows."
                            }
                        ].map((faq, index) => (
                            <div key={index} className="rounded-lg border border-slate-200 bg-white/80 p-6 backdrop-blur-sm">
                                <button className="flex w-full items-center justify-between text-left">
                                    <span className="text-lg font-medium text-slate-900">
                                        {faq.question}
                                    </span>
                                    <ChevronDown className="h-5 w-5 text-slate-500" />
                                </button>
                                <p className="mt-4 text-slate-600">{faq.answer}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Pricing;