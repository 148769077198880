import "./NotFound.scss";
import React from "react";

const NotFound = () => {
    return (
        <div className="NotFound">
            <h1>404</h1>
            <p>Oops! This page didn't get the memo.</p>
        </div>
    );
};

export default NotFound;