import './App.css';
import React, { useState } from 'react';
import { v4 as uuidv4 } from "uuid";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import RootLayout from './layout/RootLayout';
import Consent from './Consent';

import {
    Home,
    Pricing,
    PrivacyNotice,
    UserAgreement,
    DeskMate,
    DevTools,
    Enterprise,
    OS,
    Products,
    Contact,
    SignIn,
    SignUp,
    Studio,
    NotFound
} from './pages';
// import { LayoutDashboard, Home as HomeIcon, CreditCard, Menu, Mail, LogIn, UserPlus } from 'lucide-react';

const App = () => {
    const [sessionId] = useState(uuidv4());

    return (
        <Router>
            <Consent />
            <Routes>
                {/* Studio Route */}
                <Route
                    path="/studio/*"
                    element={
                        <RootLayout variant="studio">
                            <Studio />
                        </RootLayout>
                    }
                />

                {/* Main Routes */}
                <Route
                    path="/"
                    element={
                        <RootLayout variant="main">
                            <Home sessionId={sessionId} />
                        </RootLayout>
                    }
                />

                <Route
                    path="/pricing"
                    element={
                        <RootLayout variant="main">
                            <Pricing />
                        </RootLayout>
                    }
                />

                <Route
                    path="/product/studio"
                    element={
                        <RootLayout variant="main">
                            <DevTools />
                        </RootLayout>
                    }
                />

                <Route
                    path="/product/enterprise"
                    element={
                        <RootLayout variant="main">
                            <Enterprise />
                        </RootLayout>
                    }
                />

                <Route
                    path="/products"
                    element={
                        <RootLayout variant="main">
                            <Products />
                        </RootLayout>
                    }
                />

                <Route
                    path="/product/deskmate"
                    element={
                        <RootLayout variant="main">
                            <DeskMate />
                        </RootLayout>
                    }
                />

                <Route
                    path="/product/os"
                    element={
                        <RootLayout variant="main">
                            <OS />
                        </RootLayout>
                    }
                />

                <Route
                    path="/privacy"
                    element={
                        <RootLayout variant="main">
                            <PrivacyNotice />
                        </RootLayout>
                    }
                />

                <Route
                    path="/terms"
                    element={
                        <RootLayout variant="main">
                            <UserAgreement />
                        </RootLayout>
                    }
                />

                <Route
                    path="/signin"
                    element={
                        <RootLayout variant="main">
                            <SignIn />
                        </RootLayout>
                    }
                />

                <Route
                    path="/signup"
                    element={
                        <RootLayout variant="main">
                            <SignUp />
                        </RootLayout>
                    }
                />

                <Route
                    path="/contact"
                    element={
                        <RootLayout variant="main">
                            <Contact />
                        </RootLayout>
                    }
                />

                {/* 404 Route */}
                <Route
                    path="*"
                    element={
                        <RootLayout variant="main">
                            <NotFound />
                        </RootLayout>
                    }
                />
            </Routes>
        </Router>
    );
};

export default App;