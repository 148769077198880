import React from 'react';
import {
    ArrowRight,
    Cpu,
    Sparkles,
    Brain,
    Terminal,
} from 'lucide-react';

const WelcomeButton = ({ variant = 'primary', children, href }) => (
    <a
        href={href}
        className={`
            group relative flex w-full sm:w-auto items-center justify-center gap-2
            rounded-xl px-8 py-4 text-sm font-semibold transition-all duration-300 
            ${variant === 'primary'
                ? 'bg-gradient-to-r from-red-500 to-orange-500 text-white hover:opacity-90'
                : 'border border-slate-200 bg-white/50 text-slate-800 hover:bg-white/80 backdrop-blur-sm'
            }
        `}
    >
        {children}
        <ArrowRight className="h-4 w-4 transition-transform duration-300 group-hover:translate-x-1" />
    </a>
);

const ProductCard = ({ icon: Icon, title, description, color, features, link }) => (
    <div className="group relative rounded-2xl bg-white/30 p-6 backdrop-blur-sm transition-all hover:bg-white/50 
        border border-slate-200 overflow-hidden hover:shadow-lg cursor-pointer"
        onClick={link ? () => window.location.href = link : undefined}
    >
        <div className="relative z-10">
            <div className="flex items-center gap-3">
                <Icon className={`h-8 w-8 ${color}`} />
                <h3 className="text-lg font-semibold text-slate-900">{title}</h3>
            </div>
            <p className="mt-2 text-sm text-slate-600">{description}</p>
            {features && (
                <ul className="mt-4 space-y-2">
                    {features.map((feature, idx) => (
                        <li key={idx} className="flex items-center gap-2 text-xs text-slate-500">
                            <span className="h-1 w-1 rounded-full bg-slate-300" />
                            {feature}
                        </li>
                    ))}
                </ul>
            )}
        </div>
        <div className="absolute -right-10 -top-10 h-40 w-40 rounded-full bg-slate-50 blur-3xl transition-all duration-500 group-hover:bg-slate-100" />
    </div>
);

const WelcomeSection = () => {
    return (
        <div className="relative min-h-screen w-full overflow-hidden bg-gradient-to-br from-slate-50 via-purple-50 to-red-50">
            {/* Background Elements */}
            <div className="pointer-events-none absolute inset-0 overflow-hidden">
                <div className="absolute -right-1/4 top-1/4 h-[500px] w-[500px] rounded-full bg-red-100/50 blur-[128px]" />
                <div className="absolute -left-1/4 bottom-1/4 h-[500px] w-[500px] rounded-full bg-red-100/50 blur-[128px]" />
                <div className="absolute left-1/2 top-1/2 h-[500px] w-[500px] -translate-x-1/2 -translate-y-1/2 rounded-full bg-orange-100/30 blur-[128px]" />

                {/* Grid overlay */}
                <div className="absolute inset-0 bg-[linear-gradient(to_right,rgba(0,0,0,0.02)_1px,transparent_1px),linear-gradient(to_bottom,rgba(0,0,0,0.02)_1px,transparent_1px)] bg-[size:4rem_4rem]" />
            </div>

            {/* Content */}
            <div className="relative mx-auto max-w-7xl px-4 py-20 sm:px-6 lg:px-8">
                {/* Hero Section */}
                <div className="text-center">
                    <div className="mb-8 inline-flex items-center gap-2 rounded-full bg-red-100/50 px-4 py-2 backdrop-blur-sm">
                        <Sparkles className="h-4 w-4 text-red-600" />
                        <span className="text-sm font-medium text-red-800">Your Personal AI Companion</span>
                    </div>

                    <h1 className="bg-gradient-to-r from-red-900 via-orange-800 to-red-900 bg-clip-text text-4xl font-bold tracking-tight text-transparent sm:text-6xl lg:text-7xl">
                        Your AI, Your Way
                        <br />
                        <span className="text-3xl sm:text-5xl lg:text-6xl">Tailored Intelligence for Your Journey</span>
                    </h1>

                    <p className="mx-auto mt-6 max-w-2xl text-lg text-slate-600 sm:text-xl">
                        Your AI, tailored to fit your style and needs—whether it's boosting productivity, automating tasks, or sparking creativity. The possibilities are endless.
                    </p>

                    <div className="mt-8 flex flex-col items-center justify-center gap-4 sm:flex-row">
                        <WelcomeButton variant="primary" href="/studio">
                            Start Your AI Journey
                        </WelcomeButton>
                        <WelcomeButton variant="secondary" href="/products">
                            Discover More
                        </WelcomeButton>
                    </div>
                </div>

                {/* Product Grid */}
                <div className="mt-20">
                    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                        {[
                            {
                                icon: Terminal,
                                title: "Studio",
                                description: "Your personalized AI workspace that learns and evolves",
                                color: "text-red-500",
                                features: [
                                    "Adaptive learning environment",
                                    "Personalized workflows",
                                    "Custom AI models"
                                ],
                                link: "/product/studio"
                            },
                            {
                                icon: Brain,
                                title: "DeskMate",
                                description: "Secure, local-first AI that respects your privacy",
                                color: "text-orange-500",
                                features: [
                                    "Local processing",
                                    "End-to-end encryption",
                                    "Data sovereignty"
                                ],
                                link: "/product/deskmate"
                            },
                            {
                                icon: Cpu,
                                title: "OS 0",
                                description: "An operating system that thinks with you",
                                color: "text-red-500",
                                features: [
                                    "Cognitive assistance",
                                    "Predictive computing",
                                    "Seamless integration"
                                ],
                                link: "/product/os"
                            }
                        ].map((product, index) => (
                            <ProductCard key={index} {...product} />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WelcomeSection;