import React, { useState, useRef, useEffect, useCallback } from 'react';
import { RotateCcw, Send, Bot } from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';

const INITIAL_MESSAGE = {
    content: "Hi! I'm your AI assistant. How can I help you today?",
    role: 'assistant'
};

const MessageInput = ({ onSend, onReset, disabled }) => {
    const [inputValue, setInputValue] = useState('');

    const handleSend = useCallback(() => {
        if (inputValue.trim()) {
            onSend(inputValue.trim());
            setInputValue('');
        }
    }, [inputValue, onSend]);

    const handleKeyDown = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSend();
        }
    }, [handleSend]);

    const handleInputChange = useCallback((e) => {
        setInputValue(e.target.value);
        e.target.style.height = 'auto';
        e.target.style.height = `${Math.min(e.target.scrollHeight, 150)}px`;
    }, []);

    return (
        <div className="flex items-center gap-2 border-t border-gray-200 bg-white p-4">
            <button
                onClick={onReset}
                className="flex h-10 w-10 items-center justify-center rounded-lg text-gray-500 hover:bg-gray-100 active:bg-gray-200"
                aria-label="Reset chat"
            >
                <RotateCcw className="h-4 w-4" />
            </button>
            <textarea
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                placeholder="Type a message..."
                className="flex-1 resize-none rounded-xl border border-gray-200 bg-gray-50 px-4 py-3 focus:border-orange-500 focus:bg-white focus:outline-none focus:ring-1 focus:ring-orange-500 disabled:cursor-not-allowed disabled:opacity-50"
                rows={1}
                disabled={disabled}
                style={{ overflow: 'hidden' }}
            />
            <button
                onClick={handleSend}
                disabled={disabled || !inputValue.trim()}
                className="flex h-10 items-center justify-center rounded-lg bg-orange-500 px-4 font-medium text-white transition-colors hover:bg-orange-600 disabled:cursor-not-allowed disabled:bg-gray-300"
            >
                <Send className="h-5 w-5" />
            </button>
        </div>
    );
};

const ChatBox = ({ token, sessionId, setSessionId, settings }) => {
    const [messages, setMessages] = useState([INITIAL_MESSAGE]);
    const [isTyping, setIsTyping] = useState(false);
    const chatMessagesRef = useRef(null);

    useEffect(() => {
        if (chatMessagesRef.current) {
            chatMessagesRef.current.scrollTo({
                top: chatMessagesRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }
    }, [messages]);

    const handleReset = useCallback(() => {
        setMessages([INITIAL_MESSAGE]);
        setIsTyping(false);
        setSessionId(uuidv4());
    }, [setSessionId]);

    const addMessage = useCallback((content, role) => {
        setMessages(prev => [...prev, { content, role }]);
    }, []);

    const fetchResponse = useCallback(async (userInput) => {
        try {
            const response = await fetch('/api/private/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    message: userInput,
                    session_id: sessionId,
                })
            });

            if (response.ok) {
                const data = await response.json();
                return data.message;
            }

            if (response.status === 429) {
                const data = await response.json();
                const minutesUntilReset = Math.ceil(data.time_until_reset / 60);
                return `Rate limit reached. Please try again in ${minutesUntilReset} minute${minutesUntilReset === 1 ? '' : 's'}.`;
            }

            throw new Error('Failed to fetch response');
        } catch (error) {
            console.error('Error fetching response:', error);
            return "I apologize, but I encountered an error. Please try again.";
        }
    }, [token, sessionId]);

    const handleSend = useCallback(async (message) => {
        addMessage(message, 'user');
        setIsTyping(true);
        const response = await fetchResponse(message);
        setIsTyping(false);
        addMessage(response, 'assistant');
    }, [addMessage, fetchResponse]);

    return (
        <div className="flex h-full flex-col bg-gray-50">
            {/* Chat Header */}
            <div className="flex items-center justify-between border-b border-gray-200 bg-white px-6 py-4">
                <div className="flex items-center gap-3">
                    <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-orange-50 text-orange-500">
                        <Bot className="h-6 w-6" />
                    </div>
                    <div>
                        <h2 className="font-semibold text-gray-900">
                            {settings?.name || 'AI Assistant'}
                        </h2>
                        <p className="text-sm text-gray-500">
                            {settings?.field || 'General Assistant'} • {settings?.tone || 'Professional'} tone
                        </p>
                    </div>
                </div>
            </div>

            {/* Chat Messages */}
            <div
                className="flex-1 overflow-y-auto p-4 scroll-smooth"
                ref={chatMessagesRef}
            >
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`mb-4 flex ${message.role === 'assistant' ? 'justify-start' : 'justify-end'}`}
                    >
                        <div
                            className={`max-w-[80%] rounded-xl px-4 py-3 ${
                                message.role === 'assistant'
                                    ? 'bg-white shadow-sm text-gray-800 border border-gray-100'
                                    : 'bg-orange-500 text-white'
                            }`}
                        >
                            {message.content}
                        </div>
                    </div>
                ))}
                {isTyping && (
                    <div className="mb-4 flex justify-start">
                        <div className="max-w-[80%] rounded-xl bg-white shadow-sm border border-gray-100 px-4 py-3">
                            <div className="flex gap-1">
                                <div className="h-2 w-2 animate-bounce rounded-full bg-orange-500 [animation-delay:-0.3s]"></div>
                                <div className="h-2 w-2 animate-bounce rounded-full bg-orange-500 [animation-delay:-0.2s]"></div>
                                <div className="h-2 w-2 animate-bounce rounded-full bg-orange-500"></div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* Input Area */}
            <MessageInput
                onSend={handleSend}
                onReset={handleReset}
                disabled={isTyping}
            />
        </div>
    );
};

export default ChatBox;