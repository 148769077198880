import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Brain, Menu, X, ChevronDown, LaptopIcon, CodeIcon, BrainCircuitIcon } from 'lucide-react';
import { auth } from '../auth/Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';

const ProductDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const productItems = [
        {
            title: "Studio",
            description: "APIs and integrations for builders",
            icon: CodeIcon,
            href: "/product/studio"
        },
        {
            title: "Enterprise",
            description: "Custom solutions for organizations",
            icon: BrainCircuitIcon,
            href: "/product/enterprise"
        },
        {
            title: "DeskMate",
            description: "Your data, your machine, your AI",
            icon: LaptopIcon,
            href: "/product/deskmate"
        },
        {
            title: "OS 0",
            description: "Where silicon meets cognition",
            icon: Brain,
            href: "/product/os"
        }
    ];

    return (
        <div className="relative" ref={dropdownRef}>
            <button
                onClick={() => setIsOpen(!isOpen)}
                className="flex items-center space-x-1 text-sm font-medium text-gray-600 hover:text-gray-900"
            >
                <span>Product</span>
                <ChevronDown className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
            </button>

            {isOpen && (
                <div className="absolute left-0 mt-2 w-80 rounded-xl bg-white p-1 shadow-lg ring-1 ring-black ring-opacity-5">
                    {productItems.map((item) => (
                        <Link
                            key={item.href}
                            to={item.href}
                            className="flex items-start space-x-4 rounded-lg p-3 transition-colors hover:bg-gray-50"
                            onClick={() => setIsOpen(false)}
                        >
                            <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-orange-50 text-orange-600">
                                <item.icon className="h-5 w-5" />
                            </div>
                            <div>
                                <p className="text-sm font-medium text-gray-900">{item.title}</p>
                                <p className="text-sm text-gray-500">{item.description}</p>
                            </div>
                        </Link>
                    ))}
                </div>
            )}
        </div>
    );
};

const RootLayout = ({ children, variant = 'main' }) => {
    const [user] = useAuthState(auth);
    const location = useLocation();
    const [, setIsHome] = useState(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        setIsHome(location.pathname === '/');
    }, [location]);

    return (
        <div className="min-h-screen flex flex-col bg-white">
            {variant === 'main' && (
                <header className="h-16 fixed top-0 left-0 right-0 z-50 bg-gradient-to-br from-slate-50 via-purple-50 to-red-50">
                    {/* Background Elements */}
                    <div className="pointer-events-none absolute inset-0 overflow-hidden">
                        <div className="absolute -right-1/2 top-0 h-32 w-32 rounded-full bg-red-100/50 blur-2xl" />
                        <div className="absolute -left-1/2 bottom-0 h-32 w-32 rounded-full bg-red-100/50 blur-2xl" />
                        <div className="absolute left-1/2 top-0 h-32 w-32 -translate-x-1/2 rounded-full bg-orange-100/30 blur-2xl" />
                    </div>
                    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                        <div className="flex h-16 items-center justify-between">
                            {/* Logo */}
                            <Link to="/" className="flex items-center space-x-3">
                                <img src="/logo.svg" alt="Logo" className="h-8 w-8" />
                                <div className="flex flex-col">
                                    <div className="flex items-center space-x-2">
                                        <span className="font-semibold text-gray-900">Botenders</span>
                                        {variant === 'main' && (
                                            <span className="text-sm text-gray-500 border border-gray-500 rounded-full px-2 py-1 flex items-center justify-center">beta</span>
                                        )}
                                    </div>
                                    {variant === 'studio' && (
                                        <span className="text-sm text-gray-500">Studio</span>
                                    )}
                                </div>
                            </Link>

                            {/* Desktop Navigation */}
                            <nav className="hidden md:flex items-center space-x-8">
                                <ProductDropdown />
                                {[
                                    { label: 'Pricing', href: '/pricing' },
                                    { label: 'Contact', href: '/contact' },
                                    { label: 'Studio', href: (user ? '/studio' : '/signin'), special: true }
                                ].map(({ label, href, special }) => (
                                    <Link
                                        key={href}
                                        to={href}
                                        className={`text-sm font-medium transition-colors ${special
                                            ? 'text-orange-600 hover:text-orange-700'
                                            : 'text-gray-600 hover:text-gray-900'
                                            }`}
                                    >
                                        {label}
                                    </Link>
                                ))}
                            </nav>

                            {/* Auth Buttons */}
                            <div className="hidden md:flex items-center space-x-4">
                                {!user ? (
                                    <>
                                        <Link
                                            to="/signin"
                                            className="text-sm font-medium text-gray-600 hover:text-gray-900"
                                        >
                                            Sign In
                                        </Link>
                                        <Link
                                            to="/signup"
                                            className="rounded-lg px-4 py-2 text-sm font-medium bg-orange-600 text-white hover:bg-orange-700"
                                        >
                                            Sign Up
                                        </Link>
                                    </>
                                ) : (
                                    <button
                                        onClick={() => auth.signOut()}
                                        className="rounded-lg px-4 py-2 text-sm font-medium bg-orange-600 text-white hover:bg-orange-700"
                                    >
                                        Sign Out
                                    </button>
                                )}
                            </div>

                            {/* Mobile Menu Button */}
                            <button
                                onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}
                                className="md:hidden rounded-lg p-2 text-gray-600 hover:bg-gray-100"
                            >
                                {isMobileMenuOpen ? (
                                    <X className="h-6 w-6" />
                                ) : (
                                    <Menu className="h-6 w-6" />
                                )}
                            </button>
                        </div>
                    </div>

                    {/* Mobile Navigation Menu with updated Product section */}
                    {isMobileMenuOpen && (
                        <div className="md:hidden border-t border-gray-200 bg-white">
                            <div className="space-y-1 px-4 py-3">
                                {/* Product Section */}
                                <div className="space-y-2 py-2">
                                    <div className="px-3 text-sm font-medium text-gray-900">Products</div>
                                    {[
                                        {
                                            title: "Studio",
                                            description: "APIs and integrations for builders",
                                            icon: CodeIcon,
                                            href: "/product/studio"
                                        },
                                        {
                                            title: "Enterprise",
                                            description: "Custom solutions for organizations",
                                            icon: BrainCircuitIcon,
                                            href: "/product/enterprise"
                                        },
                                        {
                                            title: "DeskMate",
                                            description: "Your data, your machine, your AI",
                                            icon: LaptopIcon,
                                            href: "/product/deskmate"
                                        },
                                        {
                                            title: "OS 0",
                                            description: "Where silicon meets cognition",
                                            icon: Brain,
                                            href: "/product/os"
                                        }
                                    ].map((item) => (
                                        <Link
                                            key={item.href}
                                            to={item.href}
                                            className="block px-3 py-2"
                                            onClick={() => setMobileMenuOpen(false)}
                                        >
                                            <div className="text-base font-medium text-gray-900">{item.title}</div>
                                            <div className="text-sm text-gray-500">{item.description}</div>
                                        </Link>
                                    ))}
                                </div>

                                {/* Rest of mobile menu items */}
                                {[
                                    { label: 'Pricing', href: '/pricing' },
                                    { label: 'Contact', href: '/contact' },
                                    { label: 'Studio', href: (user ? '/studio' : '/signin'), special: true }
                                ].map(({ label, href }) => (
                                    <Link
                                        key={href}
                                        to={href}
                                        className="block px-3 py-2 text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                                        onClick={() => setMobileMenuOpen(false)}
                                    >
                                        {label}
                                    </Link>
                                ))}

                                <div className="mt-4 space-y-2">
                                    {!user ? (
                                        <>
                                            <Link
                                                to="/signin"
                                                className="block w-full px-3 py-2 text-center text-base font-medium text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                                                onClick={() => setMobileMenuOpen(false)}
                                            >
                                                Sign In
                                            </Link>
                                            <Link
                                                to="/signup"
                                                className="block w-full px-3 py-2 text-center text-base font-medium bg-orange-600 text-white hover:bg-orange-700 rounded-lg"
                                                onClick={() => setMobileMenuOpen(false)}
                                            >
                                                Sign Up
                                            </Link>
                                        </>
                                    ) : (<>
                                        <button
                                            onClick={() => auth.signOut()}
                                            className="block w-full px-3 py-2 text-center text-base font-medium bg-orange-600 text-white hover:bg-orange-700 rounded-lg"
                                        >
                                            Sign Out
                                        </button>
                                    </>)}
                                </div>

                            </div>
                        </div>
                    )}
                </header>
            )}

            <main className="flex-1">
                {children}
            </main>
        </div>
    );
};

export default RootLayout;