import React, { useState } from 'react';
import {
    Mail,
    MessageSquare,
    // Phone,
    // MapPin,
    Twitter,
    Github,
    Linkedin,
    Globe,
    CheckCircle,
    Loader2
} from 'lucide-react';

const ContactCard = ({ icon: Icon, title, description, link, linkText }) => (
    <div className="rounded-xl border border-gray-200 bg-white p-6 transition-all hover:shadow-lg">
        <div className="flex items-start space-x-4">
            <div className="rounded-lg bg-orange-50 p-3">
                <Icon className="h-6 w-6 text-orange-600" />
            </div>
            <div className="flex-1">
                <h3 className="font-medium text-gray-900">{title}</h3>
                <p className="mt-1 text-sm text-gray-500">{description}</p>
                {link && (
                    <a
                        href={link}
                        className="mt-2 inline-block text-sm font-medium text-orange-600 hover:text-orange-700"
                    >
                        {linkText}
                    </a>
                )}
            </div>
        </div>
    </div>
);

const FAQItem = ({ question, answer }) => (
    <div className="border-b border-gray-200 py-4">
        <h4 className="text-base font-medium text-gray-900">{question}</h4>
        <p className="mt-2 text-sm text-gray-500">{answer}</p>
    </div>
);

const Contact = () => {
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        subject: '',
        message: ''
    });
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitStatus, setSubmitStatus] = useState(null);

    const handleChange = (e) => {
        setFormState(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        try {
            //   await emailjs.sendForm(
            //     "service_f4lf26h",
            //     "template_b589gg6",
            //     e.target,
            //     "cffM9ylj1_Y6nV9uj"
            //   );
            setSubmitStatus('success');
            setFormState({ name: '', email: '', subject: '', message: '' });
        } catch (error) {
            console.error(error);
            setSubmitStatus('error');
        } finally {
            setIsSubmitting(false);
            setTimeout(() => setSubmitStatus(null), 5000);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 py-12 sm:py-16 lg:py-20">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                {/* Header */}
                <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Get in Touch
                    </h1>
                    <p className="mx-auto mt-6 max-w-2xl text-lg text-gray-500">
                        Have questions about Personal AI? We're here to help.
                        Choose your preferred way to connect with us.
                    </p>
                </div>

                {/* Contact Cards */}
                <div className="mt-12 grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                    <ContactCard
                        icon={Mail}
                        title="Email"
                        description="Drop us a line anytime. We'll get back to you within 24 hours."
                        link="mailto:hello@botenders.com"
                        linkText="hello@botenders.com"
                    />
                    <ContactCard
                        icon={MessageSquare}
                        title="Live Chat"
                        description="Chat with our team in real-time during business hours."
                        link="#"
                        linkText="Start a conversation"
                    />
                    <ContactCard
                        icon={Globe}
                        title="Social Media"
                        description="Follow us for updates and reach out on your preferred platform."
                        link="#"
                        linkText="See all channels"
                    />
                </div>

                {/* Contact Form Section */}
                <div className="mt-16">
                    <div className="rounded-2xl bg-white shadow-xl">
                        <div className="grid lg:grid-cols-2">
                            {/* Form */}
                            <div className="p-8 sm:p-10">
                                <h2 className="text-2xl font-bold text-gray-900">
                                    Send us a message
                                </h2>
                                <p className="mt-3 text-gray-500">
                                    Fill out the form below and we'll get back to you as soon as possible.
                                </p>

                                <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                                    <div>
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            name="name"
                                            id="name"
                                            value={formState.name}
                                            onChange={handleChange}
                                            className="mt-1 block w-full rounded-lg border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-500 focus:border-orange-500 focus:ring-orange-500"
                                            placeholder="Your name"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            name="email"
                                            id="email"
                                            value={formState.email}
                                            onChange={handleChange}
                                            className="mt-1 block w-full rounded-lg border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-500 focus:border-orange-500 focus:ring-orange-500"
                                            placeholder="you@example.com"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="subject" className="block text-sm font-medium text-gray-700">
                                            Subject
                                        </label>
                                        <input
                                            type="text"
                                            name="subject"
                                            id="subject"
                                            value={formState.subject}
                                            onChange={handleChange}
                                            className="mt-1 block w-full rounded-lg border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-500 focus:border-orange-500 focus:ring-orange-500"
                                            placeholder="What's this about?"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="message" className="block text-sm font-medium text-gray-700">
                                            Message
                                        </label>
                                        <textarea
                                            name="message"
                                            id="message"
                                            value={formState.message}
                                            onChange={handleChange}
                                            rows="4"
                                            className="mt-1 block w-full rounded-lg border border-gray-300 px-4 py-2 text-gray-900 placeholder-gray-500 focus:border-orange-500 focus:ring-orange-500"
                                            placeholder="Your message..."
                                            required
                                        ></textarea>
                                    </div>

                                    <div>
                                        <button
                                            type="submit"
                                            disabled={isSubmitting}
                                            className="flex w-full items-center justify-center rounded-lg bg-orange-600 px-8 py-3 text-white hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:opacity-50"
                                        >
                                            {isSubmitting ? (
                                                <>
                                                    <Loader2 className="mr-2 h-5 w-5 animate-spin" />
                                                    Sending...
                                                </>
                                            ) : (
                                                'Send Message'
                                            )}
                                        </button>
                                    </div>

                                    {submitStatus === 'success' && (
                                        <div className="rounded-lg bg-green-50 p-4 text-green-800">
                                            <div className="flex items-center">
                                                <CheckCircle className="mr-2 h-5 w-5" />
                                                Message sent successfully!
                                            </div>
                                        </div>
                                    )}

                                    {submitStatus === 'error' && (
                                        <div className="rounded-lg bg-red-50 p-4 text-red-800">
                                            <div className="flex items-center">
                                                <CheckCircle className="mr-2 h-5 w-5" />
                                                Failed to send message. Please try again.
                                            </div>
                                        </div>
                                    )}
                                </form>
                            </div>

                            {/* Info Panel */}
                            <div className="bg-gray-50 p-8 sm:p-10">
                                <div className="flex flex-col h-full">
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Additional Information
                                    </h3>

                                    {/* Office Hours */}
                                    <div className="mt-6">
                                        <h4 className="text-sm font-medium text-gray-900">Office Hours</h4>
                                        <div className="mt-2 space-y-1 text-sm text-gray-500">
                                            <p>Monday - Friday: 9:00 AM - 6:00 PM EST</p>
                                            <p>Saturday: 10:00 AM - 2:00 PM EST</p>
                                            <p>Sunday: Closed</p>
                                        </div>
                                    </div>

                                    {/* Social Links */}
                                    <div className="mt-6">
                                        <h4 className="text-sm font-medium text-gray-900">Follow Us</h4>
                                        <div className="mt-4 flex space-x-4">
                                            <a href="https://twitter.com" className="text-gray-400 hover:text-gray-500">
                                                <Twitter className="h-6 w-6" />
                                            </a>
                                            <a href="https://github.com" className="text-gray-400 hover:text-gray-500">
                                                <Github className="h-6 w-6" />
                                            </a>
                                            <a href="https://linkedin.com" className="text-gray-400 hover:text-gray-500">
                                                <Linkedin className="h-6 w-6" />
                                            </a>
                                        </div>
                                    </div>

                                    {/* FAQ Section */}
                                    <div className="mt-8">
                                        <h4 className="text-sm font-medium text-gray-900">
                                            Frequently Asked Questions
                                        </h4>
                                        <div className="mt-4 space-y-4">
                                            <FAQItem
                                                question="How quickly do you respond?"
                                                answer="We aim to respond to all inquiries within 24 hours during business days."
                                            />
                                            <FAQItem
                                                question="Do you offer technical support?"
                                                answer="Yes, our team provides comprehensive technical support for all our products."
                                            />
                                            <FAQItem
                                                question="Can I schedule a demo?"
                                                answer="Absolutely! You can schedule a demo through our live chat or by sending us an email."
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;