import React, { useState, useEffect } from "react";
import { auth } from '../../../auth/Firebase';
import { useAuthState } from "react-firebase-hooks/auth";
import { updatePassword, signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import {
    User,
    KeyRound,
    CreditCard,
    LogOut,
    Trash2,
    Eye,
    EyeOff,
    Loader2,
    AlertCircle
} from 'lucide-react';

const AccountSection = ({ title, icon: Icon, fields, children }) => (
    <div className="rounded-xl border border-gray-200 bg-white p-6 shadow-sm">
        <div className="mb-6 flex items-center gap-3">
            <div className="flex h-10 w-10 items-center justify-center rounded-lg bg-orange-50 text-orange-500">
                <Icon className="h-5 w-5" />
            </div>
            <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
        </div>
        <div className="space-y-4">
            {fields.map(({ label, name, type, value, onChange, disabled }, index) => (
                <div key={index} className="space-y-1.5">
                    <label htmlFor={name} className="block text-sm font-medium text-gray-700">
                        {label}
                    </label>
                    <input
                        type={type}
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        disabled={disabled}
                        required={!disabled}
                        className={`w-full rounded-lg border px-3 py-2 outline-none transition-colors ${disabled
                            ? 'cursor-not-allowed border-gray-200 bg-gray-50 text-gray-500'
                            : 'border-gray-300 focus:border-orange-500 focus:ring-1 focus:ring-orange-500'
                            }`}
                    />
                </div>
            ))}
            {children}
        </div>
    </div>
);

const SettingsActions = () => (
    <div className="flex items-center justify-end gap-3">
        <button
            type="button"
            onClick={() => window.location.reload()}
            className="rounded-lg px-4 py-2 text-gray-600 hover:text-gray-800"
        >
            Cancel
        </button>
        <button
            type="submit"
            className="rounded-lg bg-orange-500 px-4 py-2 font-medium text-white transition-colors hover:bg-orange-600"
        >
            Save Changes
        </button>
    </div>
);

const Account = () => {
    const [user] = useAuthState(auth);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [personalInfo, setPersonalInfo] = useState({ name: "", email: "", phone: "" });
    const [passwordInfo, setPasswordInfo] = useState({ newPassword: "", confirmPassword: "" });
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            if (!user) return;
            try {
                const resp = await fetch('/api/account/info', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${await user.getIdToken(true)}`,
                    },
                });

                if (resp.ok) {
                    const { name, email } = await resp.json();
                    setPersonalInfo({ name, email, phone: "" });
                }
            } catch (err) {
                console.error('Failed to fetch user data:', err);
            } finally {
                setLoading(false);
            }
        };
        fetchUserData();
    }, [user]);

    const handleInputChange = (e, stateUpdater) => {
        const { name, value } = e.target;
        stateUpdater(prevState => ({ ...prevState, [name]: value }));
    };

    const handleUpdate = async (e, endpoint, payload, successMessage, errorMessage) => {
        e.preventDefault();
        setError(null);
        try {
            const resp = await fetch(endpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await user.getIdToken(true)}`,
                },
                body: JSON.stringify(payload),
            });

            if (resp.ok) {
                toast.success(successMessage);
            } else {
                throw new Error(errorMessage);
            }
        } catch (error) {
            console.error(errorMessage, error);
            setError(errorMessage);
            toast.error(errorMessage);
        }
    };

    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        if (passwordInfo.newPassword !== passwordInfo.confirmPassword) {
            setError("Passwords do not match");
            toast.error("Passwords do not match");
            return;
        }

        try {
            await updatePassword(user, passwordInfo.newPassword);
            toast.success("Password updated successfully!");
            setPasswordInfo({ newPassword: "", confirmPassword: "" });
        } catch (error) {
            console.error('Failed to update password:', error);
            setError("Failed to update password");
            toast.error("Failed to update password");
        }
    };

    const handleSignOut = async () => {
        try {
            await signOut(auth);
            toast.success("Signed out successfully");
            window.location.href = "/signin";
        } catch (error) {
            console.error('Failed to sign out:', error);
            toast.error("Failed to sign out");
        }
    };

    const handleBilling = async () => {
        try {
            const resp = await fetch('/api/billing/portal', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await user.getIdToken(true)}`,
                },
                body: JSON.stringify({ return_url: window.location.href }),
            });

            if (resp.ok) {
                const { redirect_url } = await resp.json();
                window.location.href = redirect_url;
            } else {
                console.error('Failed to create billing portal session');
            }
        } catch (error) {
            console.error('Billing error:', error);
        }
    };

    const handleDeleteAccount = async () => {
        if (window.confirm("Are you sure you want to delete your account? This action cannot be undone.")) {
            try {
                // Add your account deletion logic here
                toast.success("Account deleted successfully.");
                window.location.href = "/";
            } catch (error) {
                console.error('Error deleting account:', error);
                setError("Failed to delete account.");
                toast.error("Failed to delete account.");
            }
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-[400px]">
                <Loader2 className="h-8 w-8 animate-spin text-orange-500" />
            </div>
        );
    }

    return (
        <div className="p-4 md:p-6 lg:p-8">
            <div className="mx-auto max-w-4xl space-y-6">
                <div className="flex items-center justify-end">
                    <button
                        onClick={handleSignOut}
                        className="flex items-center gap-2 rounded-lg bg-gray-100 px-4 py-2 text-gray-600 transition-colors hover:bg-gray-200"
                    >
                        <LogOut className="h-4 w-4" />
                        Sign Out
                    </button>
                </div>

                {error && (
                    <div className="flex items-center gap-2 rounded-lg bg-red-50 p-4 text-red-600">
                        <AlertCircle className="h-5 w-5 flex-shrink-0" />
                        {error}
                    </div>
                )}

                <form onSubmit={(e) => handleUpdate(e, '/api/public/user/update', {
                    userId: user.uid,
                    username: personalInfo.name,
                    email: personalInfo.email,
                    phone: personalInfo.phone,
                }, "Personal information updated successfully!", "Failed to update personal information")}>
                    <AccountSection
                        title="Personal Information"
                        icon={User}
                        fields={[
                            { label: "Name", name: "name", type: "text", value: personalInfo.name, onChange: (e) => handleInputChange(e, setPersonalInfo) },
                            { label: "Email", name: "email", type: "email", value: personalInfo.email, disabled: true },
                            { label: "Phone Number", name: "phone", type: "text", value: personalInfo.phone, onChange: (e) => handleInputChange(e, setPersonalInfo) },
                        ]}
                    />
                    <div className="mt-4">
                        <SettingsActions />
                    </div>
                </form>

                <form onSubmit={handlePasswordUpdate}>
                    <AccountSection
                        title="Change Password"
                        icon={KeyRound}
                        fields={[
                            { label: "New Password", name: "newPassword", type: showPassword ? "text" : "password", value: passwordInfo.newPassword, onChange: (e) => handleInputChange(e, setPasswordInfo) },
                            { label: "Confirm Password", name: "confirmPassword", type: showPassword ? "text" : "password", value: passwordInfo.confirmPassword, onChange: (e) => handleInputChange(e, setPasswordInfo) },
                        ]}
                    >
                        <div className="mt-3 flex items-center gap-2">
                            <button
                                type="button"
                                onClick={() => setShowPassword(!showPassword)}
                                className="flex items-center gap-1.5 text-sm text-gray-600"
                            >
                                {showPassword ? (
                                    <>
                                        <EyeOff className="h-4 w-4" /> Hide Passwords
                                    </>
                                ) : (
                                    <>
                                        <Eye className="h-4 w-4" /> Show Passwords
                                    </>
                                )}
                            </button>
                        </div>
                    </AccountSection>
                    <div className="mt-4">
                        <SettingsActions />
                    </div>
                </form>

                <AccountSection
                    title="Billing Information"
                    icon={CreditCard}
                    fields={[]}
                >
                    <button
                        onClick={handleBilling}
                        className="rounded-lg bg-gradient-to-r from-orange-500 to-orange-600 px-4 py-2 font-medium text-white shadow-sm transition-all hover:from-orange-600 hover:to-orange-700 hover:shadow"
                    >
                        Manage Billing
                    </button>
                </AccountSection>

                <AccountSection
                    title="Delete Account"
                    icon={Trash2}
                    fields={[]}
                >
                    <div className="space-y-3">
                        <p className="text-sm text-gray-600">
                            Permanently delete your account and all your data. This action cannot be undone.
                        </p>
                        <button
                            onClick={handleDeleteAccount}
                            className="rounded-lg bg-red-500 px-4 py-2 font-medium text-white transition-colors hover:bg-red-600"
                        >
                            Delete Account
                        </button>
                    </div>
                </AccountSection>
            </div>
        </div>
    );
};

export default Account;