import { auth } from '../../auth/Firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Account, Developer, Documentation, Integrations } from "./pages";
import { Instance } from "./components";
import Analytics from "./Analytics/Analytics";
import React, { useState, useEffect } from 'react';
import YAML from 'yaml';
import { Link } from 'react-router-dom';
import { Menu, X, ChevronRight, Layout, GitBranch, BarChart2, Book, Settings } from 'lucide-react';

const DocSample = () => {
    const sampleSpec = YAML.parse(`openapi: 3.0.0
info:
  title: Botenders API
  version: '1.0.0'
  description: |
    The Botenders API enables you to create, manage, and interact with AI chatbots.
    Build powerful conversational experiences with our simple, yet flexible API.
  contact:
    name: Botenders Support
    email: support@botenders.com
servers:
  - url: https://api.botenders.com/v1
    description: Production API Server
security:
  - ApiKeyAuth: []

components:
  securitySchemes:
    ApiKeyAuth:
      type: apiKey
      in: header
      name: X-API-Key
      description: |
        All API requests must include your API key in the X-API-Key header.
        You can find your API key in the Developer section of the Botenders Studio.

  schemas:
    Bot:
      type: object
      properties:
        id:
          type: string
          description: Unique identifier for the bot
        name:
          type: string
          description: Display name of the bot
        description:
          type: string
          description: Brief description of the bot's purpose
        status:
          type: string
          enum: [active, inactive, training]
        created_at:
          type: string
          format: date-time
        updated_at:
          type: string
          format: date-time

    Message:
      type: object
      properties:
        id:
          type: string
        content:
          type: string
        role:
          type: string
          enum: [user, assistant]
        timestamp:
          type: string
          format: date-time

    Conversation:
      type: object
      properties:
        id:
          type: string
        bot_id:
          type: string
        messages:
          type: array
          items:
            $ref: '#/components/schemas/Message'
        metadata:
          type: object
          additionalProperties: true

paths:
  /bots:
    get:
      summary: List all bots
      description: Returns a list of all bots associated with your account
      responses:
        '200':
          description: A list of bots
          content:
            application/json:
              schema:
                type: array
                items:
                  $ref: '#/components/schemas/Bot'
    post:
      summary: Create a new bot
      description: Create a new chatbot instance
      requestBody:
        required: true
        content:
          application/json:
            schema:
              type: object
              required:
                - name
              properties:
                name:
                  type: string
                description:
                  type: string
      responses:
        '201':
          description: Bot created successfully
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/Bot'

  /bots/{bot_id}:
    parameters:
      - name: bot_id
        in: path
        required: true
        schema:
          type: string
        description: The ID of the bot
    get:
      summary: Get bot details
      description: Returns detailed information about a specific bot
      responses:
        '200':
          description: Bot details
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/Bot'
    
  /bots/{bot_id}/chat:
    post:
      summary: Send a message to bot
      description: Send a message and receive a response from the specified bot
      parameters:
        - name: bot_id
          in: path
          required: true
          schema:
            type: string
      requestBody:
        required: true
        content:
          application/json:
            schema:
              type: object
              required:
                - message
              properties:
                message:
                  type: string
                conversation_id:
                  type: string
                  description: Optional conversation ID for continuing a chat
      responses:
        '200':
          description: Bot's response
          content:
            application/json:
              schema:
                $ref: '#/components/schemas/Message'

  /conversations:
    get:
      summary: List conversations
      description: Returns a list of all conversations across all bots
      parameters:
        - name: bot_id
          in: query
          schema:
            type: string
          description: Optional filter by bot ID
      responses:
        '200':
          description: List of conversations
          content:
            application/json:
              schema:
                type: array
                items:
                  $ref: '#/components/schemas/Conversation'

  /analytics/overview:
    get:
      summary: Get analytics overview
      description: Returns overview metrics for all bots
      parameters:
        - name: timeframe
          in: query
          schema:
            type: string
            enum: [day, week, month, year]
          description: Time period for the analytics
      responses:
        '200':
          description: Analytics overview
          content:
            application/json:
              schema:
                type: object
                properties:
                  total_conversations:
                    type: integer
                  total_messages:
                    type: integer
                  average_response_time:
                    type: number
                  active_users:
                    type: integer`);
    return <Documentation openApiSpec={sampleSpec} />;
};

const Studio = () => {
    const [user] = useAuthState(auth);
    const [token, setToken] = useState(null);
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [isAccountPage, setIsAccountPage] = useState(false);
    const [selectedOption, setSelectedOption] = useState('Instances');

    const navigationItems = [
        { title: 'Instances', icon: <Layout className="w-5 h-5" />, content: <Instance token={token} /> },
        { title: 'Integrations', icon: <GitBranch className="w-5 h-5" />, content: <Integrations token={token} /> },
        { title: 'Analytics', icon: <BarChart2 className="w-5 h-5" />, content: <Analytics /> },
        { title: 'Documentation', icon: <Book className="w-5 h-5" />, content: <DocSample /> },
        { title: 'Developers', icon: <Settings className="w-5 h-5" />, content: <Developer token={token} /> }
    ];

    useEffect(() => {
        if (user) {
            user.getIdToken().then(setToken);
        } else {
            setToken(null);
            window.location.href = '/signin';
        }
    }, [user]);

    const handleOptionClick = (title) => {
        setSelectedOption(title);
        setIsAccountPage(false);
    };

    return (
        <div className="h-screen flex bg-white">
            {/* Sidebar */}
            <div
                className={`
          ${isSidebarOpen ? 'translate-x-0' : '-translate-x-full'} 
          fixed inset-y-0 left-0 w-64 transition duration-300 transform bg-white border-r border-gray-200 
          lg:static lg:inset-0 lg:translate-x-0 z-30
        `}
            >
                {/* Logo section */}
                <div className="flex items-center justify-between h-16 px-6 border-b">
                    <Link to="/" className="flex items-center space-x-3">
                        <img src="/logo.svg" alt="Logo" className="w-8 h-8" />
                        <div className="flex flex-col">
                            <span className="font-semibold text-gray-900">Botenders</span>
                            <span className="text-sm text-gray-500">Studio</span>
                        </div>
                    </Link>
                    <button
                        onClick={() => setSidebarOpen(false)}
                        className="lg:hidden"
                    >
                        <X className="w-6 h-6 text-gray-500" />
                    </button>
                </div>

                {/* Navigation */}
                <nav className="flex-1 px-4 py-4 space-y-1">
                    {navigationItems.map((item) => (
                        <button
                            key={item.title}
                            onClick={() => handleOptionClick(item.title)}
                            className={`flex items-center w-full px-4 py-2 text-sm rounded-lg ${selectedOption === item.title
                                ? 'bg-blue-50 text-orange-700'
                                : 'text-gray-600 hover:bg-gray-50'
                                }`}
                        >
                            {item.icon}
                            <span className="ml-3">{item.title}</span>
                            {selectedOption === item.title && (
                                <ChevronRight className="w-4 h-4 ml-auto" />
                            )}
                        </button>
                    ))}
                </nav>

                {/* User profile */}
                <div className="flex items-center px-6 py-3 border-t">
                    <img
                        src={user?.photoURL || "/default-profile.png"}
                        alt="Profile"
                        className="w-8 h-8 rounded-full"
                    />
                    <div className="ml-3">
                        <p className="text-sm font-medium text-gray-700">{user?.displayName || 'User'}</p>
                        <button
                            onClick={() => setIsAccountPage(true)}
                            className="text-xs text-gray-500 hover:text-gray-700"
                        >
                            View Account
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile sidebar overlay */}
            {isSidebarOpen && (
                <div
                    className="fixed inset-0 z-20 bg-black bg-opacity-50 lg:hidden"
                    onClick={() => setSidebarOpen(false)}
                />
            )}

            {/* Main content */}
            <div className="flex-1 flex flex-col min-w-0 overflow-hidden bg-white">
                {/* Top bar */}
                <div className="flex items-center h-16 px-4 border-b bg-white">
                    <button
                        onClick={() => setSidebarOpen(true)}
                        className="lg:hidden"
                    >
                        <Menu className="w-6 h-6 text-gray-500" />
                    </button>
                    <h1 className="ml-4 text-xl font-semibold text-gray-800">
                        {isAccountPage ? 'Account Settings' : selectedOption}
                    </h1>
                </div>

                {/* Page content */}
                <div className="flex-1 overflow-auto bg-gray-50">
                    {isAccountPage ? (
                        <div className="h-full">
                            <Account />
                        </div>
                    ) : (
                        <div className="h-full p-6">
                            {navigationItems.find(item => item.title === selectedOption)?.content}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Studio;