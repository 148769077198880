import React, { useState } from "react";
import ChatBox from "./ChatBox";
import {
    Bot,
    Briefcase,
    MessageCircle,
    Code,
    BookOpen,
    HeartHandshake,
    GraduationCap,
    Microscope,
    PenTool,
    AlertCircle,
    ChevronLeft,
    ChevronRight
} from 'lucide-react';
import { v4 as uuidv4 } from 'uuid';

const ToneButton = ({ active, icon: Icon, label, onClick }) => (
    <button
        onClick={onClick}
        className={`flex items-center gap-3 w-full p-3 rounded-lg transition-all duration-200 ${active
                ? 'bg-orange-50 text-orange-600 border border-orange-200'
                : 'hover:bg-gray-50 text-gray-600'
            }`}
    >
        <Icon size={20} />
        <span className="text-sm font-medium">{label}</span>
    </button>
);

const ConfigureSection = ({ title, children }) => (
    <div className="space-y-3">
        <h3 className="text-sm font-semibold text-gray-700">{title}</h3>
        {children}
    </div>
);

const Sidebar = ({ instance, token, isExpanded, setIsExpanded, currentSettings, setCurrentSettings, isEditing, setIsEditing }) => {
    const [status, setStatus] = useState({ loading: false, error: null });

    const handleSave = async () => {
        setStatus({ loading: true, error: null });
        try {
            const response = await fetch('/api/instance/set', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify(currentSettings)
            });

            if (!response.ok) {
                throw new Error('Failed to update settings');
            }

            setIsEditing(false);
        } catch (error) {
            setStatus({ loading: false, error: error.message });
        }
    };

    const toneOptions = [
        { id: 'professional', icon: Briefcase, label: 'Professional' },
        { id: 'friendly', icon: HeartHandshake, label: 'Friendly' },
        { id: 'technical', icon: Code, label: 'Technical' },
        { id: 'casual', icon: MessageCircle, label: 'Casual' },
        { id: 'academic', icon: BookOpen, label: 'Academic' }
    ];

    const fieldOptions = [
        { id: 'technology', icon: Code, label: 'Technology' },
        { id: 'business', icon: Briefcase, label: 'Business' },
        { id: 'education', icon: GraduationCap, label: 'Education' },
        { id: 'science', icon: Microscope, label: 'Science' },
        { id: 'creative', icon: PenTool, label: 'Creative' }
    ];

    return (
        <div className={`h-full bg-white border-r border-gray-200 transition-all duration-300 ${isExpanded ? 'w-80' : 'w-16'
            }`}>
            <div className="flex flex-col h-full">
                {/* Header */}
                <div className="p-4 border-b border-gray-200">
                    <div className="flex items-center justify-between">
                        {isExpanded && (
                            <div className="flex items-center gap-3">
                                <div className="p-2 bg-orange-50 rounded-lg">
                                    <Bot className="w-5 h-5 text-orange-500" />
                                </div>
                                <span className="font-semibold text-gray-900">AI Assistant</span>
                            </div>
                        )}
                        <button
                            onClick={() => setIsExpanded(!isExpanded)}
                            className="p-2 text-gray-400 hover:text-gray-600 rounded-lg hover:bg-gray-50"
                        >
                            {isExpanded ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
                        </button>
                    </div>
                </div>

                {/* Main Content */}
                {isExpanded && (
                    <div className="flex-1 overflow-y-auto p-4 space-y-6">
                        {/* Settings Sections */}
                        <div className="space-y-6">
                            {/* Name Section */}
                            <ConfigureSection title="Assistant Name">
                                {isEditing ? (
                                    <input
                                        type="text"
                                        value={currentSettings.name}
                                        onChange={(e) => setCurrentSettings(prev => ({ ...prev, name: e.target.value }))}
                                        className="w-full p-2 border border-gray-200 rounded-lg focus:border-orange-500 focus:ring-1 focus:ring-orange-500"
                                    />
                                ) : (
                                    <div className="text-gray-600 p-2">{currentSettings.name}</div>
                                )}
                            </ConfigureSection>

                            {/* Tone Section */}
                            <ConfigureSection title="Communication Style">
                                <div className="space-y-2">
                                    {toneOptions.map(option => (
                                        <ToneButton
                                            key={option.id}
                                            active={currentSettings.tone === option.id}
                                            icon={option.icon}
                                            label={option.label}
                                            onClick={() => isEditing && setCurrentSettings(prev => ({ ...prev, tone: option.id }))}
                                        />
                                    ))}
                                </div>
                            </ConfigureSection>

                            {/* Field Section */}
                            <ConfigureSection title="Field of Expertise">
                                <div className="space-y-2">
                                    {fieldOptions.map(option => (
                                        <ToneButton
                                            key={option.id}
                                            active={currentSettings.field === option.id}
                                            icon={option.icon}
                                            label={option.label}
                                            onClick={() => isEditing && setCurrentSettings(prev => ({ ...prev, field: option.id }))}
                                        />
                                    ))}
                                </div>
                            </ConfigureSection>

                            {/* Custom Instructions */}
                            <ConfigureSection title="Custom Instructions">
                                {isEditing ? (
                                    <textarea
                                        value={currentSettings.custom_instruction}
                                        onChange={(e) => setCurrentSettings(prev => ({ ...prev, custom_instruction: e.target.value }))}
                                        className="w-full p-2 border border-gray-200 rounded-lg focus:border-orange-500 focus:ring-1 focus:ring-orange-500 min-h-[100px]"
                                        placeholder="Add any specific requirements..."
                                    />
                                ) : (
                                    <div className="text-gray-600 p-2">
                                        {currentSettings.custom_instruction || 'No custom instructions set'}
                                    </div>
                                )}
                            </ConfigureSection>
                        </div>

                        {/* Error Message */}
                        {status.error && (
                            <div className="p-3 bg-red-50 border border-red-200 rounded-lg flex items-center text-red-600">
                                <AlertCircle className="w-4 h-4 mr-2" />
                                {status.error}
                            </div>
                        )}
                    </div>
                )}

                {/* Footer */}
                {isExpanded && (
                    <div className="p-4 border-t border-gray-200">
                        {isEditing ? (
                            <div className="flex gap-2">
                                <button
                                    onClick={() => setIsEditing(false)}
                                    className="flex-1 py-2 px-4 border border-gray-200 rounded-lg text-gray-600 hover:bg-gray-50"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    disabled={status.loading}
                                    className="flex-1 py-2 px-4 bg-orange-500 text-white rounded-lg hover:bg-orange-600 disabled:opacity-50"
                                >
                                    {status.loading ? 'Saving...' : 'Save Changes'}
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={() => setIsEditing(true)}
                                className="w-full py-2 px-4 bg-orange-500 text-white rounded-lg hover:bg-orange-600"
                            >
                                Edit Settings
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

const Configure = ({ instance, token }) => {
    const [sessionId, setSessionId] = useState(uuidv4());
    const [isExpanded, setIsExpanded] = useState(true);
    const [isEditing, setIsEditing] = useState(false);
    const [currentSettings, setCurrentSettings] = useState({
        name: instance?.name || '',
        tone: instance?.tone || 'professional',
        field: instance?.field || 'technology',
        use_case: instance?.use_case || 'assistant',
        custom_instruction: instance?.custom_instruction || ''
    });

    // In Configure component
    return (
        <div className="h-full flex">
            <Sidebar
                instance={instance}
                token={token}
                isExpanded={isExpanded}
                setIsExpanded={setIsExpanded}
                currentSettings={currentSettings}
                setCurrentSettings={setCurrentSettings}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
            />
            <div className="flex-1 min-w-0"> {/* min-w-0 prevents flex item overflow */}
                <ChatBox
                    token={token}
                    sessionId={sessionId}
                    setSessionId={setSessionId}
                    settings={currentSettings}
                />
            </div>
        </div>
    );
};

export default Configure;