import React from "react";
import {
    Cpu,
    Globe,
    Construction,
    Lock,
    SparklesIcon,
    MessagesSquare
} from "lucide-react";

const OS = () => {
    return (
        <div className="min-h-screen bg-gradient-to-br from-black via-orange-950 to-black">
            {/* Hero Section */}
            <section className="px-4 py-32 text-center">
                <div className="mx-auto max-w-4xl">
                    <div className="mb-6 inline-flex items-center gap-2 rounded-full bg-orange-500/10 px-4 py-2 backdrop-blur-sm">
                        <Construction className="h-4 w-4 text-orange-400" />
                        <span className="text-orange-400 font-medium">Early Development</span>
                    </div>
                    <h1 className="mt-6 bg-gradient-to-r from-orange-400 via-purple-400 to-orange-400 bg-clip-text text-5xl font-bold tracking-tight text-transparent sm:text-7xl">
                        Reimagining
                        <br />
                        Computing
                    </h1>
                    <div className="mt-8 flex justify-center gap-4">
                        <div className="h-px w-8 self-center bg-orange-800" />
                        <span className="text-lg text-orange-200/80">Coming Soon</span>
                        <div className="h-px w-8 self-center bg-orange-800" />
                    </div>
                </div>
            </section>

            {/* Mysterious Features Grid */}
            <section className="px-4 py-16">
                <div className="mx-auto max-w-6xl">
                    <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                        {[
                            {
                                icon: Cpu,
                                title: "Kernel-Level Integration",
                                description: "A fundamental reimagining of how AI interacts with your system."
                            },
                            {
                                icon: SparklesIcon,
                                title: "Zero Abstraction",
                                description: "Direct neural processing without traditional computational overhead."
                            },
                            {
                                icon: Lock,
                                title: "Built-in Privacy",
                                description: "Security and privacy engineered from the ground up."
                            },
                            {
                                icon: Globe,
                                title: "System-Wide Intelligence",
                                description: "Seamless integration across every level of computation."
                            },
                            {
                                icon: MessagesSquare,
                                title: "Native AI Communication",
                                description: "Direct neural interfaces at the operating system level."
                            },
                            {
                                icon: Construction,
                                title: "Under Development",
                                description: "Reshaping the future of computing. Stay tuned."
                            }
                        ].map((feature, index) => (
                            <div
                                key={index}
                                className="group relative overflow-hidden rounded-2xl border border-orange-900/50 bg-black/20 p-6 backdrop-blur-sm transition-all hover:bg-black/30"
                            >
                                <div className="relative z-10">
                                    <feature.icon className="h-8 w-8 text-orange-400" />
                                    <h3 className="mt-4 text-xl font-semibold text-orange-200">
                                        {feature.title}
                                    </h3>
                                    <p className="mt-2 text-orange-200/70">{feature.description}</p>
                                </div>
                                <div 
                                    className="absolute -right-10 -top-10 h-40 w-40 rounded-full bg-orange-600/5 blur-3xl transition-all duration-500 group-hover:bg-orange-600/10" 
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </section>

            {/* Footer Teaser */}
            <section className="px-4 py-32 text-center">
                <div className="mx-auto max-w-2xl">
                    <div className="inline-flex items-center gap-2 rounded-full bg-black/30 px-4 py-2 backdrop-blur-sm">
                        <Lock className="h-4 w-4 text-orange-400" />
                        <span className="text-orange-200/80">More details coming soon</span>
                    </div>
                </div>
            </section>

            {/* Mysterious Background Elements */}
            <div className="pointer-events-none fixed inset-0 -z-10">
                {/* Glowing orbs */}
                <div className="absolute left-1/4 top-1/4 h-96 w-96 -translate-x-1/2 -translate-y-1/2 rounded-full bg-orange-900/20 blur-3xl" />
                <div className="absolute right-1/4 top-3/4 h-96 w-96 -translate-x-1/2 -translate-y-1/2 rounded-full bg-purple-900/20 blur-3xl" />
                
                {/* Grid overlay */}
                <div className="absolute inset-0 bg-[linear-gradient(to_right,rgba(255,115,0,0.05)_1px,transparent_1px),linear-gradient(to_bottom,rgba(255,115,0,0.05)_1px,transparent_1px)] bg-[size:4rem_4rem]" />
            </div>
        </div>
    );
};

export default OS;