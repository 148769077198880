import React, { useState, useEffect } from 'react';

// Custom hook for typewriter effect
const useTypewriter = (text, speed = 50, delay = 0) => {
    const [displayText, setDisplayText] = useState('');
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isStarted, setIsStarted] = useState(false);

    useEffect(() => {
        // Initial delay before starting
        const startTimeout = setTimeout(() => {
            setIsStarted(true);
        }, delay);

        return () => clearTimeout(startTimeout);
    }, [delay]);

    useEffect(() => {
        if (!isStarted) return;

        if (currentIndex < text.length) {
            const timeout = setTimeout(() => {
                setDisplayText(prev => prev + text[currentIndex]);
                setCurrentIndex(prev => prev + 1);
            }, speed);

            return () => clearTimeout(timeout);
        }
    }, [currentIndex, text, speed, isStarted]);

    return displayText;
};

const AnimatedHeader = () => {
    const line1 = useTypewriter("Your Personal AI Assistant,", 50, 0);
    const line2 = useTypewriter("Entirely On Your Machine", 50, 1500); // Start after first line

    return (
        <h1 className="bg-gradient-to-r from-orange-400 to-pink-300 bg-clip-text text-5xl font-bold text-transparent sm:text-6xl">
            {line1}
            <br />
            {line2}
            {/* Blinking cursor */}
            <span className="animate-pulse">|</span>
        </h1>
    );
};

export default AnimatedHeader;