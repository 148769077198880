import React, { useState, useEffect } from "react";
import Configure from "./Configure";
import Initialize from "./Initialize";
import { Loader2 } from "lucide-react";

const fetchInstance = async (token) => {
    try {
        const response = await fetch('/api/instance/info', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error fetching instance info:', error);
        return null;
    }
};

const Instance = ({ token }) => {
    const [instance, setInstance] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (token) {
            setIsLoading(true);
            fetchInstance(token)
                .then(data => {
                    setInstance(data);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [token]);

    if (isLoading) {
        return (
            <div className="h-full w-full flex items-center justify-center">
                <div className="flex flex-col items-center gap-4">
                    <Loader2 className="h-8 w-8 animate-spin text-orange-500" />
                    <p className="text-gray-600 font-medium">Loading your assistant...</p>
                </div>
            </div>
        );
    }

    return (
        <div className="h-full w-full">
            {instance ? (
                // When instance exists, Configure fits within the container
                <div className="h-full w-full overflow-hidden rounded-xl bg-white shadow-sm border border-gray-200">
                    <Configure instance={instance} token={token} />
                </div>
            ) : (
                // When no instance, Initialize is centered in the container
                <div className="h-full w-full flex items-center justify-center">
                    <div className="w-full max-w-5xl">
                        <Initialize token={token} setInstance={setInstance} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Instance;