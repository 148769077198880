import React, { useState, useEffect } from 'react';
import { LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { Users, MessageCircle, Clock, Zap, ChevronDown } from 'lucide-react';

// Placeholder data organized by instance
const PLACEHOLDER_DATA = {
    'instance-1': {
        name: "Production Bot",
        metrics: {
            users: { value: "2,543", change: 12.5 },
            conversations: { value: "1,879", change: 8.2 },
            responseTime: { value: "2.4s", change: -15.8 },
            successRate: { value: "75%", change: 5.3 }
        },
        usageData: [
            { date: 'Mon', users: 400 },
            { date: 'Tue', users: 300 },
            { date: 'Wed', users: 500 },
            { date: 'Thu', users: 450 },
            { date: 'Fri', users: 600 },
            { date: 'Sat', users: 400 },
            { date: 'Sun', users: 350 }
        ],
        performanceData: [
            { name: 'Success', value: 75 },
            { name: 'Failed', value: 25 }
        ],
        responseTimeData: [
            { hour: '00:00', time: 2.4 },
            { hour: '04:00', time: 1.8 },
            { hour: '08:00', time: 3.2 },
            { hour: '12:00', time: 4.1 },
            { hour: '16:00', time: 3.8 },
            { hour: '20:00', time: 2.5 }
        ],
        popularTopics: [
            { topic: 'Account Setup', percentage: 90 },
            { topic: 'Billing Issues', percentage: 70 },
            { topic: 'Technical Support', percentage: 50 },
            { topic: 'Feature Requests', percentage: 30 }
        ]
    },
    'instance-2': {
        name: "Staging Bot",
        metrics: {
            users: { value: "1,245", change: 5.2 },
            conversations: { value: "943", change: 3.1 },
            responseTime: { value: "1.9s", change: -8.4 },
            successRate: { value: "82%", change: 7.8 }
        },
        usageData: [
            { date: 'Mon', users: 200 },
            { date: 'Tue', users: 150 },
            { date: 'Wed', users: 250 },
            { date: 'Thu', users: 225 },
            { date: 'Fri', users: 300 },
            { date: 'Sat', users: 200 },
            { date: 'Sun', users: 175 }
        ],
        performanceData: [
            { name: 'Success', value: 82 },
            { name: 'Failed', value: 18 }
        ],
        responseTimeData: [
            { hour: '00:00', time: 1.9 },
            { hour: '04:00', time: 1.5 },
            { hour: '08:00', time: 2.1 },
            { hour: '12:00', time: 2.4 },
            { hour: '16:00', time: 2.2 },
            { hour: '20:00', time: 1.8 }
        ],
        popularTopics: [
            { topic: 'Test Cases', percentage: 85 },
            { topic: 'Integration Issues', percentage: 65 },
            { topic: 'Bug Reports', percentage: 45 },
            { topic: 'Documentation', percentage: 25 }
        ]
    }
};

// API Functions
const analyticsApi = {
    /**
     * Fetch list of instances for the current user
     * @param {string} token - User's authentication token
     * @returns {Promise<Array<{id: string, name: string}>>}
     */
    fetchInstances: async (token) => {
        // TODO: Implement API call
        // Expected return format:
        // [
        //   { id: "instance-1", name: "Production Bot" },
        //   { id: "instance-2", name: "Staging Bot" }
        // ]
    },

    /**
     * Fetch overview metrics for a specific instance
     * @param {string} token - User's authentication token
     * @param {string} instanceId - ID of the selected instance
     * @returns {Promise<{
     *   totalUsers: number,
     *   totalConversations: number,
     *   avgResponseTime: number,
     *   successRate: number,
     *   metrics: {
     *     previousMonth: {
     *       totalUsers: number,
     *       totalConversations: number,
     *       avgResponseTime: number,
     *       successRate: number
     *     }
     *   }
     * }>}
     */
    fetchOverviewMetrics: async (token, instanceId) => {
        // TODO: Implement API call
    },

    /**
     * Fetch user activity data for the selected time range
     * @param {string} token - User's authentication token
     * @param {string} instanceId - ID of the selected instance
     * @param {string} timeRange - Time range for the data (e.g., '7d', '30d')
     * @returns {Promise<Array<{date: string, users: number}>>}
     */
    fetchUserActivity: async (token, instanceId, timeRange) => {
        // TODO: Implement API call
    },

    /**
     * Fetch response time data for the last 24 hours
     * @param {string} token - User's authentication token
     * @param {string} instanceId - ID of the selected instance
     * @returns {Promise<Array<{hour: string, time: number}>>}
     */
    fetchResponseTimes: async (token, instanceId) => {
        // TODO: Implement API call
    },

    /**
     * Fetch resolution rate data
     * @param {string} token - User's authentication token
     * @param {string} instanceId - ID of the selected instance
     * @returns {Promise<{success: number, failed: number}>}
     */
    fetchResolutionRate: async (token, instanceId) => {
        // TODO: Implement API call
    },

    /**
     * Fetch popular topics
     * @param {string} token - User's authentication token
     * @param {string} instanceId - ID of the selected instance
     * @returns {Promise<Array<{topic: string, percentage: number}>>}
     */
    fetchPopularTopics: async (token, instanceId) => {
        // TODO: Implement API call
    }
};

// Component definitions (Card, CardHeader, etc.) remain the same...
const Card = ({ children, className = "" }) => (
    <div className={`bg-white rounded-lg border border-gray-200 shadow-sm ${className}`}>
        {children}
    </div>
);

const CardHeader = ({ children }) => (
    <div className="border-b border-gray-200 px-6 py-4">
        {children}
    </div>
);

const CardTitle = ({ children }) => (
    <h3 className="text-lg font-semibold text-gray-900">
        {children}
    </h3>
);

const CardContent = ({ children }) => (
    <div className="px-6 py-4">
        {children}
    </div>
);

// // Instance Selector Component
// const InstanceSelector = ({ instances, selectedInstance, onInstanceChange }) => (
//     <div className="relative">
//         <select
//             value={selectedInstance?.id}
//             onChange={(e) => onInstanceChange(instances.find(i => i.id === e.target.value))}
//             className="appearance-none w-64 bg-white border border-gray-300 rounded-lg py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-orange-500"
//         >
//             {instances.map(instance => (
//                 <option key={instance.id} value={instance.id}>
//                     {instance.name}
//                 </option>
//             ))}
//         </select>
//         <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
//             <ChevronDown className="w-4 h-4" />
//         </div>
//     </div>
// );

const AnalyticCard = ({ title, value, change, icon: Icon }) => (
    <Card>
        <CardContent className="pt-6">
            <div className="flex items-center justify-between">
                <div>
                    <p className="text-sm font-medium text-gray-500">{title}</p>
                    <h3 className="text-2xl font-bold mt-2">{value}</h3>
                    <p className={`text-sm mt-1 ${change >= 0 ? 'text-green-600' : 'text-red-600'}`}>
                        {change >= 0 ? '↑' : '↓'} {Math.abs(change)}% from last month
                    </p>
                </div>
                <div className="p-3 bg-orange-50 rounded-full">
                    <Icon className="w-6 h-6 text-orange-600" />
                </div>
            </div>
        </CardContent>
    </Card>
);

const Analytics = ({ token }) => {
    const [selectedInstanceId, setSelectedInstanceId] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    // Get available instances from placeholder data
    const instances = Object.entries(PLACEHOLDER_DATA).map(([id, data]) => ({
        id,
        name: data.name
    }));

    // Get current instance data
    const instanceData = selectedInstanceId ? PLACEHOLDER_DATA[selectedInstanceId] : null;

    // Set initial instance
    useEffect(() => {
        if (!selectedInstanceId && instances.length > 0) {
            setSelectedInstanceId(instances[0].id);
            setIsLoading(false);
        }

        analyticsApi.fetchInstances(token).then(instances => {
            // Update instances list
        });  
    }, [selectedInstanceId, instances, token]);

    if (!instanceData) {
        return <div>Loading...</div>;
    }

    const COLORS = ['green', 'red'];

    return (
        <div className="space-y-6">
            {/* Instance Selector */}
            <div className="flex items-center justify-between">
                <div className="relative">
                    <select
                        value={selectedInstanceId}
                        onChange={(e) => setSelectedInstanceId(e.target.value)}
                        className="appearance-none w-64 bg-white border border-gray-300 rounded-lg py-2 px-4 pr-8 leading-tight focus:outline-none focus:border-orange-500"
                    >
                        {instances.map(instance => (
                            <option key={instance.id} value={instance.id}>
                                {instance.name}
                            </option>
                        ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                        <ChevronDown className="w-4 h-4" />
                    </div>
                </div>
                {isLoading && (
                    <span className="text-sm text-gray-500">Loading data...</span>
                )}
            </div>

            {/* Top stats */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <AnalyticCard
                    title="Total Users"
                    value={instanceData.metrics.users.value}
                    change={instanceData.metrics.users.change}
                    icon={Users}
                />
                <AnalyticCard
                    title="Conversations"
                    value={instanceData.metrics.conversations.value}
                    change={instanceData.metrics.conversations.change}
                    icon={MessageCircle}
                />
                <AnalyticCard
                    title="Avg. Response Time"
                    value={instanceData.metrics.responseTime.value}
                    change={instanceData.metrics.responseTime.change}
                    icon={Clock}
                />
                <AnalyticCard
                    title="Success Rate"
                    value={instanceData.metrics.successRate.value}
                    change={instanceData.metrics.successRate.change}
                    icon={Zap}
                />
            </div>

            {/* Charts */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* User Activity */}
                <Card>
                    <CardHeader>
                        <CardTitle>User Activity</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={instanceData.usageData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="date" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="users" fill="#3B82F6" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>

                {/* Response Time */}
                <Card>
                    <CardHeader>
                        <CardTitle>Response Time (24h)</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={instanceData.responseTimeData}>
                                    <CartesianGrid strokeDasharray="3 3" />
                                    <XAxis dataKey="hour" />
                                    <YAxis />
                                    <Tooltip />
                                    <Line type="monotone" dataKey="time" stroke="#3B82F6" />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </CardContent>
                </Card>

                {/* Success Rate */}
                <Card>
                    <CardHeader>
                        <CardTitle>Resolution Rate</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                    <Pie
                                        data={instanceData.performanceData}
                                        innerRadius={60}
                                        outerRadius={80}
                                        paddingAngle={5}
                                        dataKey="value"
                                    >
                                        {instanceData.performanceData.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                        ))}
                                    </Pie>
                                    <Tooltip />
                                </PieChart>
                            </ResponsiveContainer>
                            <div className="flex justify-center gap-4 mt-4">
                                {instanceData.performanceData.map((entry, index) => (
                                    <div key={entry.name} className="flex items-center">
                                        <div
                                            className="w-3 h-3 rounded-full mr-2"
                                            style={{ backgroundColor: COLORS[index] }}
                                        />
                                        <span className="text-sm">{entry.name}: {entry.value}%</span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </CardContent>
                </Card>

                {/* Popular Topics */}
                <Card>
                    <CardHeader>
                        <CardTitle>Popular Topics</CardTitle>
                    </CardHeader>
                    <CardContent>
                        <div className="space-y-4">
                            {instanceData.popularTopics.map(({ topic, percentage }) => (
                                <div key={topic} className="flex items-center">
                                    <div className="flex-1">
                                        <div className="flex justify-between mb-1">
                                            <span className="text-sm font-medium">{topic}</span>
                                            <span className="text-sm text-gray-500">{percentage}%</span>
                                        </div>
                                        <div className="w-full bg-gray-200 rounded-full h-2">
                                            <div
                                                className="bg-orange-600 h-2 rounded-full"
                                                style={{ width: `${percentage}%` }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </CardContent>
                </Card>
            </div>
        </div>
    );
};

export default Analytics;